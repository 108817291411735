import axios from 'axios';
import { getToken, clearForLogout } from '@/utils/auth';
import store from '@/store';
import router from '@/router';
import { errorsAlert } from './requestAlerts';

const service = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: 300000 // request timeout
});
service.interceptors.request.use(
  config => {
    loaderIncrease(config);
    let token = getToken();
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  response => {
    loaderDecrease(response.config);
    const res = response.data;
    if (response.status !== 200) {
      return Promise.reject(new Error(res.message || 'Error'));
    } else {
      return res;
    }
  },
  error => {
    loaderDecrease(error.config);
    errorsAlert(error.response);
    console.log("error.response", error.response);
    if (error.response.status == 401) {
      logout();
    }
    return Promise.reject(error.response);
  }
);

function logout() {
  if (router.currentRoute.name != 'Login') {
    clearForLogout();
    return router.push({ name: 'Login' });
  }
}

const notLoader = [
  'classifiers.position'
];
function getRequestBodyMethod(config){
  return config['data']['method']?config['data']['method']:null;
}
function loaderIncrease(config) {
  if (notLoader.indexOf(getRequestBodyMethod(config)) < 0) {
    store.dispatch('loader/increase');
  }
}
function loaderDecrease(config) {
  if (notLoader.indexOf(getRequestBodyMethod(config)) < 0) {
    store.dispatch('loader/decrease');
  }
}

export default service;
