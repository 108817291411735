import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import auth from './auth';
import loader from './loader';
import structures from './structures'
import classifiers from './classifiers'
import workers from './workers'
import positions from './positions'

const store = new Vuex.Store({
  modules: {
    auth,
    loader,
    structures,
    classifiers,
    workers,
    positions
  }
});

export default store;

const initialStateCopy = JSON.parse(JSON.stringify(store.state));

export function resetState() {
  // let storeData = JSON.parse(JSON.stringify(store.state));
  // initialStateCopy['constants'] = storeData['constants'];
  store.replaceState(JSON.parse(JSON.stringify(initialStateCopy)));
}