<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { getToken, checkTokenTime } from "@/utils/auth";
export default {
  data(){
    return {
    }
  },
  created() {
  },
  mounted(){
    this.cacheClear();
    this.checkAuth();
  },
  watch: {},
  computed: {},
  methods: {
    ...mapActions({
      getCurrentUser: 'auth/currentUser',
    }),
    cacheClear(){
      if(caches){
        caches.keys().then((keyList) => Promise.all(keyList.map((key) => caches.delete(key))))
        console.log('cache deleted')
      }
    },
    checkAuth(){
      let token = getToken();
      if(token && checkTokenTime()){
        this.getCurrentUser();
      }
    }
  }
};
</script>

<style lang="scss">
@import './styles.scss';
@import './assets/styles/media.scss';
</style>
