import { setItem } from '@/utils/storage';

export const mutations = {
  SET_USER: (state, data) => {
    state.user = data;
    state.role_name = data && data.roles && data.roles.length ? data.roles[0].name : "";
    if (state.user) {
      setItem('user', state.user);
    }
  },
  SET_IS_AUTH: (state, value) => {
    state.is_auth = value;
  },
};