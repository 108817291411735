import { method } from '@/api/api';

export const actions = {
  list({ commit }, data) {
    return new Promise((resolve, reject) => {
      method("structures.list", data)
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_LIST', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  treeWithPositions({ commit }) {
    return new Promise((resolve, reject) => {
      method("structures.withpositions")
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_TREE_WITH_POSITIONS', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  create({ commit }, data) {
    return new Promise((resolve, reject) => {
      method("structures.create", data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  delete({ commit }, data) {
    return new Promise((resolve, reject) => {
      method("structures.delete", data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
};
