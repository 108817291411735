import Vue from 'vue'

import { VueMaskFilter } from 'v-mask'
import { formatDate, firstCharUppercase, formatPrice, upper, translate } from "@/filters";
Vue.filter("first-ch-upper", firstCharUppercase);
Vue.filter("price", formatPrice);
Vue.filter("upper", upper);
Vue.filter('date', formatDate);
Vue.filter('t', translate);
Vue.filter('VMask', VueMaskFilter)
