<template>
  <el-input type="tel" key="phone" v-model="model" :placeholder="$t(placeholder)" v-mask="'#########'">
    <template slot="prepend">+998</template>
  </el-input>
</template>

<script>

export default {
  props:{
    value: {
      type: String
    },
    placeholder: {
      type: String
    }
  },
  data(){
    return {
      model: ''
    }
  },
  watch: {
    model(value){
      if (value && value.length) {
        this.$emit('input', '+998'+value);
      } else {
        this.$emit('input', '');
      }
    }
  },
  mounted(){
    if (this.value && this.value.indexOf('+998') == 0) {
      this.model = this.value.slice(4);
    } else {
      this.model = this.value?this.value:"";
    }
  },
  computed: {}
}
</script>
