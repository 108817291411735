import { method } from '@/api/api';

export const actions = {
  list({ commit }, data) {
    return new Promise((resolve, reject) => {
      method("workers.list", data)
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_LIST', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  create({ commit }, data) {
    return new Promise((resolve, reject) => {
      method("workers.create", data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  show({ commit }, data) {
    return new Promise((resolve, reject) => {
      method("workers.show", data)
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_MODEL', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  dismiss({ commit }, data) {
    return new Promise((resolve, reject) => {
      method("workers.dismiss", data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  changePosition({ commit }, data) {
    return new Promise((resolve, reject) => {
      method("workers.change-position", data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  generateContract({ commit }, data) {
    return new Promise((resolve, reject) => {
      method("workers.generate-contract", data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
};
