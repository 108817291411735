import { method } from '@/api/api';

export const actions = {
  create({ commit }, data) {
    return new Promise((resolve, reject) => {
      method("positions.create", data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  delete({ commit }, id) {
    return new Promise((resolve, reject) => {
      method("positions.delete", id)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
};
