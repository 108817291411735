import Vue from 'vue'

import NotFound from './pages/NotFound';
Vue.component("not-found", NotFound);

import DropdownDatepicker from './dropdown-datepicker/dropdown-datepicker';
Vue.component("dropdown-datepicker", DropdownDatepicker);

import SalaryInput from './elements/SalaryInput';
Vue.component("salary-input", SalaryInput);

import EmptyPage from './EmptyPage';
Vue.component('empty-page', EmptyPage);

import FormOption from '@/components/elements/FormOption';
Vue.component('form-option', FormOption);

import Editor from './elements/Editor';
Vue.component('editor', Editor);

import PhoneMask from './elements/PhoneMask';
Vue.component('phone-input', PhoneMask);

import Loader from './elements/Loader';
Vue.component('v-loader', Loader);

import TreeSelect from './elements/TreeSelect';
Vue.component('tree-select', TreeSelect);

import SearchSelect from './elements/SearchSelect';
Vue.component('search-select', SearchSelect);