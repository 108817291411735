<template>
  <div class="el-input">
    <input type="tel" :maxlength="maxLength" v-model="model" v-mask="salaryMask" :placeholder="placeholder" class="el-input__inner">
  </div>
</template>

<script>
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
const salaryMask = createNumberMask({
  prefix: '',
  allowDecimal: false,
  thousandsSeparatorSymbol: ' ',
  includeThousandsSeparator: true,
  allowNegative: false,
});
export default {
  props:{
    value: {
      type: [String,Number]
    },
    placeholder: {
      type: String
    },
    maxLength: {
      type: Number
    }
  },
  data(){
    return {
      salaryMask,
      model: ''
    }
  },
  watch: {
    model(val){
      let value = String(val).replace(/\s/g, '');
      if(this.value != value){
        this.$emit('input', value);
      }
    },
    value(val){
      let correctValue = Math.trunc(this.value);
      this.model = correctValue?correctValue:"";
    }
  },
  mounted(){
    let correctValue = Math.trunc(this.value);
    this.model = correctValue?correctValue:"";
  },
  computed: {}
}
</script>
