import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "@/store/";
import middlewares from "./middlewares";
import i18n from "@/i18n";
import { getItem,setItem } from '@/utils/storage'

import { checkTokenTime } from "@/utils/auth";

import company from './modules/company'
import employeeBase from './modules/employeeBase'

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "Main",
    redirect: { name: "Dashboard" },
    component: () =>
      import("@/layouts/Main"),
    children: [{
      path: 'dashboard',
      name: 'Dashboard',
      meta: { middleware: 'Auth' },
      component: () =>
      import("@/views/Dashboard/Index"),
    },{
      path: 'employee-base',
      name: 'EmployeeBase',
      meta: { middleware: 'Auth' },
      component: () =>
        import("@/views/EmployeeBase/Index"),
      redirect: {name: "Workers"},
      children: employeeBase
    }]
  },
  {
    path: '/login',
    name: 'Login',
    component: () =>
      import("@/views/Auth/Login"),
  },
  {
    path: '/registration',
    name: 'Registration',
    component: () =>
      import("@/views/Auth/Registration"),
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if(!to.meta['savePosition']){
      var content = document.getElementById('content');
      if(content){
        content.scrollTo(0, 0)
      }
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach((to, from, next) => {
  setLangFromQuery(to)
  if (to.meta && to.meta.middleware) {
    if (middlewares[to.meta.middleware]) {
      let $res = middlewares[to.meta.middleware](store);
      if (!$res || !checkTokenTime()) {
        return next({ name: "Login", query: {redirect_url: to.path} });
      }
    }
  }
  return next();
});

function setLangFromQuery(to){
  if (to.query.locale && !getItem('locale')) {
    i18n.locale = to.query.locale
    setItem('locale', i18n.locale)
  }
}

export default router
