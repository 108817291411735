<template>
  <span>{{text|t}}</span>
</template>

<script>
export default {
  props: {
    id: {
      type: Number | String,
      required: true
    },
    objectkey: {
      type: String,
      required: true
    }
  },
  data() {    
    return {
    }
  },
  computed:{
    text(){
      if(this.formOptions[this.objectkey]){
        let find = this.formOptions[this.objectkey].find(item => item.id == Number(this.id));
        return find?find:'';
      }
      return '';
    }
  },
  methods: {
  }
}
</script>