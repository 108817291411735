import { mapGetters, mapActions } from 'vuex'
import {cloneObject as utilsCloneObject} from '@/utils/index'
export default {
  data() {
    return {
      metaTitle: 'ISH.UZ'
    }
  },
  computed: {
    ...mapGetters({
      isAuth: 'auth/IS_AUTH',
      currentUser: 'auth/USER',
      currentUserStat: 'auth/STATISTICS',
      loadRunningGetter: 'loader/REQUEST_RUNNING',
    }),
    loadRunning(){
      return this.loadRunningGetter < 0?0:this.loadRunningGetter;
    },
    companyProfile(){
      return this.currentUser?this.currentUser['company']:null
    },
    currentUserProfile(){
      return this.currentUser?this.currentUser['profile']:null
    },
    currentCompanyUser(){
      return this.currentUserProfile?this.currentUserProfile['company_user']:null
    },
    isCompany(){
      return this.currentUser?this.currentUser['role'] == 'company':false;
    },
    isDev(){
      return process.env.NODE_ENV == "development";
    },
  },
  methods: {
    ...mapActions({
    }),
    cloneObject(object) {
      return utilsCloneObject(object);
    },
    changeQuery(key, value){
      let query = {...{}, ...this.$route.query};
      if(value){
        if(query[key] != value){
          query[key] = value;
          this.$router.push({name: this.$route.name, params: this.$route.params, query });
        }
      }else{
        delete query[key];
        this.$router.push({name: this.$route.name, params: this.$route.params, query });
      }
    },
    formInvalidValid(form){
      let first = null;
      form.fields.reverse().forEach(field => {
        if(field.validateState == 'error'){
          first = field.$el;
        }
      })
      if(first){
        first.scrollIntoView({ behavior: 'smooth'});
      }
    },
    getYear(n){
      let str1 = this.$t('год');
      let str2 = this.$t('года');
      let str5 = this.$t('лет');
      return ((((n%10)==1)&&((n%100)!=11))?(str1):(((((n%10)>=2)&&((n%10)<=4))&&(((n%100)<10)||((n%100)>=20)))?(str2):(str5)))
    },
    getAge(n){
      let str1 = this.$t('age год');
      let str2 = this.$t('age года');
      let str5 = this.$t('age лет');
      return ((((n%10)==1)&&((n%100)!=11))?(str1):(((((n%10)>=2)&&((n%10)<=4))&&(((n%100)<10)||((n%100)>=20)))?(str2):(str5)))
    },
    getMonthRu(n){
      let str1 = this.$t('месяц');
      let str2 = this.$t('месяца');
      let str5 = this.$t('месяцев');
      return ((((n%10)==1)&&((n%100)!=11))?(str1):(((((n%10)>=2)&&((n%10)<=4))&&(((n%100)<10)||((n%100)>=20)))?(str2):(str5)))
    },
    getDayRu(n){
      let str1 = this.$t('день');
      let str2 = this.$t('дня');
      let str5 = this.$t('дней');
      return ((((n%10)==1)&&((n%100)!=11))?(str1):(((((n%10)>=2)&&((n%10)<=4))&&(((n%100)<10)||((n%100)>=20)))?(str2):(str5)))
    },
    getResponseText(n){
      let str1 = this.$t('n отклик');
      let str2 = this.$t('n отклика');
      let str5 = this.$t('n откликов');
      return ((((n%10)==1)&&((n%100)!=11))?(str1):(((((n%10)>=2)&&((n%10)<=4))&&(((n%100)<10)||((n%100)>=20)))?(str2):(str5)))
    },
    getNSTextRu(n, str1, str2, str5){
      return ((((n%10)==1)&&((n%100)!=11))?(str1):(((((n%10)>=2)&&((n%10)<=4))&&(((n%100)<10)||((n%100)>=20)))?(str2):(str5)))
    },
    scrollToTop(){
      var content = document.getElementById('content');
      if(content){
        content.scrollTo(0, 0)
      }
    },
    openFile(base64, filename) {
      let link = document.createElement("a");
      link.href = base64;
      link.download = filename;
      // link.target = "_blank";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    onlyLatters(string){
      if(string){
        return String(string).replace(/[^A-Za-zА-Яа-яЁё\s-]/g,'');
      }
      return string;
    },
    changeQueries(queries){
      let query = {...{}, ...this.$route.query};
      Object.keys(queries).forEach(key => {
        var value = queries[key];
        if(value){
          if(query[key] != value){
            query[key] = value;
          }
        }else{
          delete query[key];
        }
      })
      this.$router.push({name: this.$route.name, params: this.$route.params, query });
    },
  },
};
