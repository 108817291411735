export const mutations = {
  SET_LIST: (state, data) => {
    state.list = data;
    state.tree = createTree(state.list);
  },
  SET_TREE_WITH_POSITIONS: (state, data) => {
    state.tree = createTree(data);
    state.treeWithPositions = createTreeWithPositions(data);
  }
};

function createTree(list, parent_id){
  let arr = [];
  list.forEach(item => {
    if(item.parent_id == parent_id){
      item.childs = createTree(list, item.id)
      arr.push(item);
    }
  })
  return arr;
}


function createTreeWithPositions(list, parent_id){
  let arr = [];
  list.forEach(item => {
    if(item.parent_id == parent_id){
      arr.push({
        id: item.id,
        name: item.name,
        rate: null,
        type: 'structure',
        childs: createTreeWithPositions(list, item.id).concat(item.positions.map(position => {
          return {
            id: position.id,
            name: position.name,
            rate: position.rate,
            type: 'position',
          }
        }))
      });
    }
  })
  return arr;
}