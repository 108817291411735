const routes = [
  {
    path: 'employees',
    name: 'Workers',
    meta: { middleware: 'Auth', module: 'employeeBase' },
    component: () =>
      import("@/views/EmployeeBase/Workers/Index"),
  }, 
  // {
  //   path: 'employees/create',
  //   name: 'WorkerCreate',
  //   meta: { middleware: 'Auth' },
  //   component: () =>
  //     import("@/views/EmployeeBase/Workers/Create"),
  // }, {
  //   path: 'employees/show/:id',
  //   name: 'WorkerShow',
  //   meta: { middleware: 'Auth' },
  //   component: () =>
  //     import("@/views/EmployeeBase/Workers/Show"),
  // },
  
  
  {
    path: 'departments',
    name: 'Departments',
    meta: { middleware: 'AuthCompany', module: 'employeeBase' },
    component: () =>
      import("@/views/EmployeeBase/Departments/Index"),
  }, {
    path: 'positions',
    name: 'Positions',
    meta: { middleware: 'AuthCompany', module: 'employeeBase' },
    component: () =>
      import("@/views/EmployeeBase/Positions/Index"),
  },
  {
    path: 'structure',
    name: 'Structure',
    meta: { middleware: 'AuthCompany', module: 'employeeBase' },
    component: () =>
      import("@/views/EmployeeBase/Structure/Index"),
  }
]

export default routes;
