<template>
  <div class="emptyPage">
    <div class="emptyPageInner">
      <div class="emptyPageCont">
        <div class="emptyPageContIco" v-if="iconsrc">
          <img :src="iconsrc" alt="">
        </div>
        <div class="emptyPageContText">
          {{text}}
          <template v-if="description"><br>{{description}}</template>
        </div>
      </div>
      <div v-if="brouter" class="emptyPageBtn">
        <router-link :to="brouter">
          <button class="btn btn-primary btn-2">{{btext}}</button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['text','brouter','btext','iconsrc', 'description']
}
</script>
<style>
.emptyPage{
  height: 70vh;
  max-height: 700px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 450px;
}
.emptyPageCont{
  max-width: 410px;
  margin: 0 auto;
  background: #FFFFFF;
  box-shadow: 0px 6px 40px rgba(39, 50, 134, 0.08);
  border-radius: 10px;
  padding: 50px;
  margin-bottom: 50px;
}
.emptyPageContIco{
  width: 84px;
  height: 74px;
  background: #ECF0F4;
  border-radius: 10px;
  line-height: 72px;
  margin: 0 auto 30px;
}
.emptyPageContIco img{
  max-width: 80%;
  max-height: 90%;
}
.emptyPageContText{
  opacity: 0.6;
  font-size: 16px;
  font-weight: 500;
}
@media screen and (max-width: 400px) {
  .emptyPage{
    height: auto;
    min-height: 0px;
    padding: 30px 0 80px;
  }
  .emptyPageCont{
    padding: 35px 14px 30px;
    margin-bottom: 30px;
  }
  .emptyPageContIco{
    width: 54px;
    height: 48px;
    line-height: 46px;
    margin-bottom: 20px;
  }
  .emptyPageContIco img{
    max-width: 57%;
    max-height: 65%;
  }
  .emptyPageContText{
    font-size: 14px;
  }
}
</style>