import store from '@/store'
import { MessageBox } from 'element-ui';
import i18n from "@/i18n";

export function errorsAlert(error) {
  let requestBody = error.config.data?JSON.parse(error.config.data):null;
  let requestMethodName = requestBody && requestBody['method']?requestBody['method']:"";
  let dataError = error.data.error?error.data.error:null;
  if(dataError.data && dataError.data.message){
    if(error.status == 422){
      messageBox(`<b>${requestMethodName}</b><br/> ${getValidateErrors(error)}`)
    }else{
      messageBox(`<b>${requestMethodName}</b><br/> ${dataError.data.message} |${dataError.code}`)
    }
  } else if (dataError.message) {
      messageBox(`<b>${requestMethodName}</b><br/> ${dataError.message} |${dataError.code}`)
  } else if (error.status && error.statusText){
    if (error.data.error && (error.data.error == "invalid_credentials")) {
      messageBox(`<b>${requestMethodName}</b><br/> ${errors[401]} |401`)
    }else{
      messageBox(`<b>${requestMethodName}</b><br/> ${error.statusText} |${error.status} ${getValidateErrors(error)}`)
    }
  }
}

function messageBox(message){
  MessageBox.alert(message+`<br><div class='fs14 mt-2'>[${(new Date).toLocaleString()}]<div>`, i18n.tc('Внимание'), {
    customClass: "ErrorAlertBox",
    confirmButtonText: i18n.tc('Закрыть'),
    confirmButtonClass: "btn btn-primary btn-2",
    // type: 'error',
    dangerouslyUseHTMLString: true,
    duration: 0,
    center: true,
    closeOnPressEscape: true,
    closeOnClickModal: true,
  });
}

function getValidateErrors(error) {
  let status = error.status;
  if(error.data && error.data.error){
    error = error.data.error;
  }
  if (status == 422 && error.data && error.data.errors) {
    let message = "<br/> - ";
    Object.keys(error.data.errors).forEach(filed => {
      message += error.data.errors[filed].join(' <br/> - ');
    });
    return message;
  }
  return null;
}