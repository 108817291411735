<template>
  <ElSelectTree
    v-model="model"
    :data="data"
    :props="treeProps"
    :placeholder="placeholder"
    check-strictly />
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => []
    },
    value: {
      type: [String, Number, Object, Array],
      default: () => ""
    },
    placeholder: {
      type: String,
      default: () => ''
    }
  },
  data() {    
    return {
      model: "",
      treeProps: {
        value: 'id',
        label: (data) => data.name,
        children: 'childs'
      }
    }
  },
  inject: {
    elForm: {
      default: ''
    },
    elFormItem: {
      default: ''
    }
  },
  watch: {
    value(newValue, oldValue) {
      this.model = newValue;
    },
    model(){
      if(this.elFormItem){
        this.elFormItem.validateState = '';
      }
      this.$emit('input', this.model);
      this.$emit('change', this.model);
    }
  },
  created() {
    this.model = this.value;
  },
  updated() {
    this.$emit('input', this.model);
    this.$emit('change', this.model);
  },
  methods: {
  }
}
</script>