import { generatePagination } from '@/utils'

export const mutations = {
  SET_LIST: (state, data) => {
    state.list = data.data;
    state.page = data.current_page;
    state.lastPage = data.last_page;
    state.total = data.total;
    state.pagination = generatePagination(state.page, state.lastPage);
  },
  SET_MODEL: (state, data) => {
    state.model = data;
    state.model['activeTransactions'] = getActiveTransactions(state.model.transactions);
  }
};

function getActiveTransactions(transactions) {
  if(transactions && transactions.length > 0) {
    return transactions.filter(transaction => transaction.date_stop == '9999-01-01').map(item => {
      return {
        date_start: item['date_start'],
        id: item['id'],
        position_name: item['position_name'],
        structure_name: item['structure_name'],
      };
    });
  }
  return [];
}