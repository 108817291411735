export default {
  "hello": "Salom",
  "about": {
    "example": "Tohle je stránka O nás."
  },
  "Все":"Barchasi",
  "По вашему запросу ничего не найдено":"Sizning so'rovingniz bo'yicha hech qanday natija topilmadi",
  "Очистить фильтр":"Filterni tozalash",
  "Вакансия":"Vakansiya",
  "Локация":"Hudud",
  "Отдел":"Bo'lim",
  "Крайний срок":"Oxirgi muddat",
  "Ответственный":"Mas'ul",
  "Приоритет":"Ustuvorlik darajasi",
  "У вас еще нет созданных вакансий": "Sizda hozircha e'lon qilingan vakansiyalar yo‘q",
  "У вас еще нет кандидаты":"Sizda hozircha nomzodlar yo‘q",
  "Заказчики":"Buyurtmachilar",
  "Рекрутеры":"Rekruterlar",
  "У вас еще нет заказчики":"Sizda hozircha buyurtmachilar yo‘q",
  "У вас еще нет рекрутеры":"Sizda hozircha rekruterlar yo‘q",

  "Заказчик":"Buyurtmachi",
  "Рекрутер":"Rekruter",
  "Выберите роль пользователя":"Foydalanuvchini rolini tanlang",
  "Выберите заказчика":"Buyurtmachini tanlang",
  "Крайний срок вакансии":"Vakansiyaning oxirgi muddati",
  "Ответственный рекрутер":"Mas'ul rekruter",
  "Выберите ответственного рекрутера":"Mas'ul rekruterni tanlang",
  "Низкий":"Quyi",
  "Средний":"O'rta",
  "Высокий":"Yuqori",
  "Есть опыт работы?":"Ish tajribaga egami?",
  "Дополнительное образование":"Sertifikatlar",
  "На какую вакансию добавить?":"Qaysi vakansiyaga biriktiriladi?",
  "Введите название вакансии":"Vakansiya nomini kiriting",
  "Добавить ещё опыт работы": "Yana ish tajriba qo'shish",
  "Организация":"Tashkilot",
  "Есть":"Bor",
  "Добавить ещё курс":"Yana sertifikat qo'shish",
  "Дополнительная информация":"Qo'shimcha ma'lumot",
  "Выберите этап":"Bosqichni tanlang",
  "Сменить этап":"Bosqichni o'zgartirish",

  "Комментировал":"Izoh qoldirildi",
  "Создан на этап ":"{param} bosqichida yaratildi",
  "Переведен на этап ":"{param} bosqichiga o'tqazildi",
  "Отправлен в черный список":"Qora ro'yxatga qo'shildi",
  "Активен":"Faollashtirildi",
  "Отправлен в кадровый резерв":"Kadrlar zaxirasiga qo'shildi",

  "Добавление кандидата":"Nomzod qo'shish",
  "Добавить новую":"Yangi qo'shish",
  "Введите фамилию или имя кандидата":"Nomzod ismi va familiyasini kiriting",
  "На другую вакансию":"Boshqa vakansiyaga",
  "Отказать":"Rad etish",
  "Профиль ISH.UZ":"ISH.UZ profili",
  "История действий":"Amallar tarixi",
  "Совместимость":"Moslik",
  "Метки":"Teglar",
  "Добавить в базу кандидатов":"Nomzodlar bazasiga qo'shish",
  "Добавить комментарий":"Izoh qo'shish",
  "Написать кандидату":"Nomzodga yozish",
  "Переместить в кадровый резерв":"Kadrlar zaxirasiga yuborish",
  "Отправить в черный список":"Qora ro'yxatga qo'shish",
  "Комментарии":"Izohlar",
  "Источник":"Manba",
  "На какую вакансию отправить?":"Qasyi vakasiyaga jo'natish kerak?",
  "Написать коммениарий": "Izoh yozish",
  "Всем":"Hammaga",
  "Только я":"Faqat menga",
  "На какой этап?":"Qaysi bosqichga?",
  "Кандидат уже находится в кадровом резерве":"Nomzod kadrlar zaxirasiga qo'shilgan",
  "lorem_send_archieve":"Kadrlar zaxirasi - bu ochiq bo'sh lavozimni egallash uchun zarur bo'lgan ko'nikmalarga ega bo'lgan nomzodlarning ma'lumotlar bazasi. Agar sizda ochiq vakansiyalar mavjud bo'lsa, kadrlar zaxirasida avval ko'rib chiqilgan nomzodlaridan tanlab olishingiz mumkin bo'ladi.",
  "lorem_candidate_block":"Qora ro'yxat - bu siz kelajakda ko'rib chiqmaydigan va ishga qabul qilmoqchi bo'lmagan mutaxassislar bazasi. Ushbu ro'yxatga qo'shilgan mutaxassislar sizning tavsiya etilgan nomzodlaringizda ko'rinmaydi, shuningdek chatga xabarlar yuborolmaydi.",

  "day": "{param} kun ichida",
  "days": "{param} kun ichida",
  "to_salary":"{param} gacha",
  "Еще подходящих вакансий": "Yana {param} ta mos vakansiyalari",
  "вакансий": "ta vakansiya",
  "UZS": "so'm",
  "USD": "USD",
  "RUB": "Rubl",
  "EUR": "Evro",
  "CNY": "Yaun",
  "Ташкент": "Toshkent",
  "Вакансии": "Vakansiyalar",
  "Компании": "Kompaniyalar",
  "Профессии": "Kasblar",
  "Работадателю": "Ish beruvchiga",
  "Создать резюме": "Rezyume yaratish",
  "Войти": "Kirish",
  "Поиск работы в Узбекистане": "O'zbekistonda ish izlash portali",
  "Найти работу": "Ish izlash",
  "Работодателям": "Ish beruvchilarga",
  "Категории": "Kategoriyalar",
  "Поиск": "Izlash",
  "Весь Узбекистан": "Butun O‘zbekiston",
  "Найти вакансии": "Ish topish",
  "Создать профиль": "Profil yaratish",
  "Это займет 3 минуты, и система сама для вас найдет наиболее подходящих кандидатов.": "3 daqiqadan so‘ng tizim Sizga mos nomzodni topadi",
  "Это займет 3 минуты, и система сама найдет подходящие для вас вакансии":"Bunga 3 daqiqa vaqt talab etiladi va tizim Sizga mos vakansiyalarni topadi",
  "Постоянная работа": "Doimiy ish",
  "Работа по проекту": "Loyiha bo‘yicha ish",
  "Удаленная работа": "Masofaviy ish",
  "Стажировка": "Amaliyot",
  "Работа для экспатов": "Chet elliklar uchun ish",
  "Всего объявлений": "Jami e'lonlar",
  "Работа по категориям": "Kategoriyalar bo'yicha vakansiyalar",
  "Работа в компаниях": "Kompaniyalar bo'yicha vakansiyalar",
  "Поиск работы в компании": "Barcha kompaniyalar",
  "Поиск вакансий по городам": "Shaharlar bo'yicha vakansiyalar",
  "Поиск вакансий по регионам": "Hududlar bo'yicha vakansiyalar",
  "Ish.uz использует файлы «cookie», с целью персонализации сервисов и повышения удобства пользования веб-сайтом.": "Ish.uz veb-saytdan foydalanish qulayligini oshirish uchun «cookie» fayllardan foydalanadi.",
  "«Cookie» представляют собой небольшие файлы, содержащие информацию о предыдущих посещениях веб-сайта.": "«Cookie» fayllar veb-saytga oldingi tashriflar haqidagi ma'lumotlarni o'z ichiga olgan kichik fayllardir.",
  "Если вы не хотите использовать файлы «cookie», измените настройки браузера.": "Agar Siz «cookie» fayllardan foydalanishni xohlamasangiz, brauzer sozlamalarini o'zgartiring.",
  "Вход": "Kirish",
  "Соискатель": "Ish izlovchi",
  "Работодатель": "Ish beruvchi",
  "Введите номер мобильного телефона": "Mobil telefon raqamini kiriting",
  "Далее": "Davom etish",
  "Войти с паролем": "Parol bilan kirish",
  "или": "yoki",
  "Продолжить через Google": "Google bilan davom etish",
  "Продолжить через Facebook": "Facebook bilan davom etish",
  "Введите адрес электронной почты": "E-mail manzilingizni kiriting",
  "Поле не может быть пустым": "Maydonni toʻldiring",
  "Пароль": "Parol",
  "Забыли пароль?": "Parolni unutdingizmi?",
  "Нет аккаунта?": "Profilingiz yo'qmi?",
  "Зарегистрироваться": "Roʻyxatdan oʻtish",
  "Зарегистрируйтесь и находите сотрудников": "Ro'yxatdan o'ting va xodimlarni toping",
  "Название компании": "Kompaniya nomi",
  "Контактное лицо": "Aloqa uchun ma'sul xodim",
  "ФИО контактного лица": "Aloqa uchun ma'sul xodimning FIOsi",
  "Введите Контактный номер": "Aloqa uchun raqam kiriting",
  "Повторите пароль": "Parolni qayta kiriting",
  "Пожалуйста, введите пароль еще раз": "Iltimos, parolni qayta kiriting",  
  "Нажимая кнопку “Зарегистрироваться”, вы принимаете":"«Roʻyxatdan oʻtish» tugmasini bosish orqali siz quyidagilarni qabul qilasiz:",
  "условия использования сайта":"saytdan foydalanish shartlari",
  "политику конфиденциальности":"maxfiylik siyosati",
  "Есть аккаунт?": "Profilingiz bormi?",
  "Изменить фото": "Suratni oʻzgartirish",
  "Изменить пароль":"Parolni o'zgartirish",
  "Профессия": "Kasb",
  "Город": "Shahar",
  "Дата рождения": "Tug'ilgan sana",
  "Пол": "Jins",
  "Телефон": "Telefon",
  "Ищу работу": "Ish izlayapman",
  "Не ищу работу": "Ish izlamayapman",
  "Готов выйти сразу": "Darhol chiqishga tayyor",
  "Нужно две недели": "Ikki hafta kerak",
  "Сохранить": "Saqlash",
  "Отменить": "Bekor qilish",
  "Категория": "Kategoriya",
  "Позиция": "Lavozim",
  "Мой профиль": "Mening profilim",
  "Подходящие вакансии": "Mos vakansiyalar",
  "Приглашения от работодателей": "Ish beruvchi bilan chat",
  "Отклики": "Javoblar",
  "Желаемый уровень заработной платы": "Istalgan ish haqi darajasi",
  "В этом блоке вы можете добавить свой уровень зарплаты.": "Ushbu blokda Siz o'zingizning ish haqi darajangizni qo'shishingiz mumkin.",
  "Укажите уровень образования": "Malumot darajangizni ko'rsating",
  "В этом блоке вы можете добавить образование, которое вы получили.": "Ushbu blokda Siz olgan ma'lumotingizni qo'shishingiz mumkin.",
  "Опыт работы": "Ish tajribasi",
  "Заполняя информацию об опыте работы, раскройте для работодателя ваши положительные стороны, которые соответствуют должности, на которой вы хотите работать. Опишите ваши обязанности, достижения, знания, умения и положительный опыт применения их в работе.": "Ish tajribasi to'g'risidagi ma'lumotni to'ldirganda, ish beruvchiga ishlamoqchi bo'lgan lavozimga mos keladigan ijobiy tomonlaringizni oshkor qiling. Majburiyatlar, yutuqlar, bilim, ko'nikma va ularni ishingizda qo'llash bo'yicha ijobiy tajribangizni tasvirlab bering.",
  "Нет опыта работы": "Ish tajribasi yo‘q",
  "Укажите навыки": "Qobiliyatlarni belgilang",
  "Когда вы описываете свои навыки, включите те, которые будут полезны вам в новой работе. Это покажет работодателю ваши конкретные преимущества перед другими кандидатами.": "O'z mahoratingizni tavsiflaganingizda, yangi ish joyida Sizga foydali bo'ladiganlarini qo'shing. Bu ish beruvchiga Sizning boshqa nomzodlarga nisbatan o'ziga xos afzalliklaringizni ko'rsatadi.",
  "Знание языков": "Tillarni bilishi",
  "Укажите в этом блоке, какие языки и на каком уровне вы знаете. Чтобы определить уровень, воспользуйтесь нашими рекомендациями.": "Ushbu blokda qaysi tillarni va qaysi darajada bilishingizni ko'rsating. Darajani aniqlash uchun tavsiyalarimizdan foydalaning.",
  "Категория прав": "Haydovchilik guvohnomasi toifalari",
  "Наличие автомобиля": "Avtomobil mavjudligi",
  "Есть личный автомобиль": "Shaxsiy avtomobil mavjud",
  "Добавить": "Qo'shish",
  "Отклик без резюме": "Rezyumesiz taklif yuborish",
  "Показывать только непрочитанные": "Faqat o'qilmaganlarni ko'rsatish",
  "Выход": "Chiqish",
  "Код": "Kod",
  "Хотите изменить E-mail?": "E-mail manzilingizni o'zgartirmoqchimisiz?",
  "Изменить E-mail": "E-mail manzilni o'zgartirish",
  "Настройки компании": "Kompaniya sozlamalari",
  "Поиск по кандидатам": "Nomzodlar bo'yicha izlash",
  "Баланс": "Hisobingiz",
  "пополнить": "to'ldirish",
  "Редактирование информации о компании": "Kompaniya ma'lumotlarini tahrirlash",
  "Название тип компании": "Kompaniya nomi va turi",
  "Название": "Nomi",
  "Тип": "Turi",
  "Главная": "Bosh sahifa",
  "Редактировать": "Tahrirlash",
  "Информация о компании": "Kompaniya haqida ma'lumot",
  "Отрасль": "Soha",
  "Количество сотрудников в компании": "Kompaniyadagi xodimlar soni",
  "Телефоно": "Telefon",
  "Описание компании": "Kompaniya tavsifi",
  "Ищете сотрудников?": "Xodimlarni izlayapsizmi?",
  "Регионы": "Hududlar",
  "Зарплата": "Ish haqi",
  "Вид занятости": "Bandlik turi",
  "Образование": "Malumoti",
  "Сбросить все": "Hammasini olib tashlash",
  "Предыдущая": "Oldingi",
  "Следующая": "Keyingi",
  "мужской": "erkak",
  "женский": "ayol",
  "Написать": "Yozish",
  "profile_fill":{
    "about":"O'zim haqimda",
    "computer_level_id":"Kompyuter savodxonlik",
    "driver_rights":"Haydovchilik guvohnomasi",
    "email":"E-mail",
    "languages":"Tillarni bilish",
    "phone_number":"Telefon",
    "seeker_courses":"Qo'shimcha ta'lim(kurs)",
    "seeker_educations":"Ta'lim darajasi",
    "seeker_experiences":"Mehnat faoliyati",
    "skills":"Ko'nikmalar",
    "wanted_salary":"Oylik kutilmasi"
  },
  "Полная занятость": "Doimiy bandlik",
	"Частичная занятость": "Qisman bandlik",
	"Попроектная работа или разовое задание": "Loyihaviy ishi yoki bir martalik vazifa",
	"Волонтерство": "Volontyorlik",
	"не имеет значения": "ahamiyati yo‘q",
	"Высшее бакалавр": "Maʼlumoti oliy, bakalavr",
	"Высшее магистр": "Maʼlumoti oliy, magistr",
	"Профиль заполнен на": "Profil {param} % ga to'ldirilgan",
	"Чем выше ваш процент, тем больше работодателей видят ваш профиль.": "Sizning foizingiz qanchalik yuqori bo'lsa,shunchalik ko'p ish beruvchilar Sizning profilingizni ko'radi.",
	"Постарайтесь заполнить профиль свыше 80% чтобы получать максимальное количество предложений.": "Maksimal takliflar sonini olish uchun, profilingizni 80% dan yuqoriroq to'ldirishga harakat qiling.",
	"Еще вы можете указать": "Bundan tashqari, belgilashingiz mumkin",
	"Учебное заведение": "Ta'lim muassasasi",
	"Специализация (если есть)": "Mutaxassislik (agar mavjud bo'lsa)",
	"Направление": "Yo'nalish",
	"Год окончания": "Tamomlagan yili",
	"Если вы сейчас учитесь — укажите ожидаемый год выпуска": "Agar siz hozir o'qiyotgan bo'lsangiz, kutilayotgan bitiruv yilini ko'rsating",
	"Вакансии Узбекистана": "O'zbekistonda vakansiyalari",
	"Подходящие резюме": "To'g'ri keladigan kandidatlar",
	"Истекает": "Muddati tugaydi",
	"Получить больше откликов": "Ko'proq taklif olish",
	"Действия": "Amallar",
	"Активные": "Faol",
	"Неопубликованные": "E'lon qilinmaganlar",
	"Архивные": "Arxivlangan",
	"Мои вакансии": "Mening vakansiyalarim",
	"Сохраненные резюме": "Saqlangan rezyumelar",
	"Чат с кандидатами": "Nomzodlar bilan chat",
	"Создать вакансию": "Vakansiya yaratish",
	"Название должности": "Lavozim nomi",
	"Категория размещения вакансии": "Vakansiyalarni joylashtirish kategoriyasi",
	"Например, менеджер по продажам": "Masalan, savdo menejeri",
	"Условия работы": "Ish sharoitlari",
	"Регион работы": "Ish hududi",
	"Город работы": "Ish shahri",
	"Адрес работы": "Ish manzili",
	"Улица и дом": "Ko'cha va uy",
	"Вакансии с указанной зарплатой получают в два раза больше откликов!": "Ish haqi belgilangan vakansiyalariga ikki barobar ko'p takliflar yuboriladi!",
	"Комментарий к зарплате": "Ish haqi bo'yicha izoh",
	"Диапазон": "Diapazon",
	"Одно значение": "Bitta qiymat",
	"Не указыать": "Belgilanmagan",
	"Требования к соисталелю": "Ish izlovchiga qo'yiladigan talablar",
	"Готовы взять без опыта": "Tajribasiz qabul qilishga tayyor",
	"от 1 года": "1 yildan yuqori",
	"от 2 лет": "2 yildan yuqori",
	"от 5 лет": "5 yildan yuqori",
	"Вакансия подходит для": "Vakansiya mos keladi",
	"Студента": "Talabaga",
	"Студент": "Talaba",
	"Человека с инвалидностью": "Nogironligi bo'lgan shaxsga",
	"Пенсионера": "Pensionerga",
	"Экспата иностранного специалиста": "Chet ellik mutaxassisga",
	"Описание вакансии": "Vakansiya tavsifi",
	"Требования": "Talablar",
	"Обязанности": "Majburiyatlar",
	"Ключевые навыки": "Asosiy ko'nikmalar",
	"Контактная информация": "Bog'lanish uchun ma'lumot",
	"Пользователь": "Foydalanuvchi",
	"Показывать на странице этой вакансии имя и телефон": "Ushbu bo'sh ish o'rin sahifasida ism va telefon raqamini ko'rsatish",
	"Указать другой телефон": "Boshqa telefon ko'rsatish",
	"Как можно откликнуться": "Qanday taklif yuborilishi mumkin",
	"Резюме обязательно": "Rezyume shart",
	"Можно и без резюме (только имя, фамилия и контакты)": "Rezyume shart emas (faqat ism, familiya va kontaktlari)",
	"Сколько человек вы хотите нанять по данной вакансии?": "Ushbu bo'sh ish o'rin uchun qancha odamni yollashni xohlaysiz?",
	"Продолжить": "Davom etish",
  "На сайте Ish.uz размещены вакансии в Ташкенте и регионах Узбекистана. Информация на сайте позволяет осуществлять быстрый поиск и подбор персонала в любой сфере.": "O‘zbekistonda xodimlarni topish Toshkent shahri va O‘zbekiston hududlaridagi vakansiyalari Ish.uz saytida joylayshtirilgan. Saytdagi ma'lumotlar istalgan sohada xodimlarni tezda topish va tanlash imkonini beradi.",
  "Сохранено": "Saqlangan",
  "Чтобы сохранить кандидата, нужно войти или зарегистрироваться.": "Rezyumeni saqlash uchun tizimga kirishingiz yoki ro'yxatdan o'tishingiz kerak.",
  "по дате": "sana bo'yicha",
  "по убыванию зарплат": "ish haqi kamayishi bo'yicha",
  "по возрастанию зарплаты": "ish haqi ko'payishi bo'yicha",
  "Опыт работы:": "Ish tajribasi:",
  "Место работы": "Ish joyi",
  "по настоящее время": "hozirgi vaqtga qadar",
  "Основной язык": "Asosiy til",
  "Найти кандидатов":"Xodim topish",
  "Чтобы сохранить вакансию, нужно войти или зарегистрироваться.": "Vakansiyani saqlash uchun tizimga kirishingiz yoki ro'yxatdan o'tishingiz kerak",
  "Ищете работу?": "Ищете работу?",
  "Разместите резюме": "Разместите резюме",
  "чтобы работодатели сами могли предложить вам работу.": "чтобы работодатели сами могли предложить вам работу.",
  "Откликнуться": "Taklif yuborish",
  "Вакансия от": "E'lon qilingan:",
  "Чтобы откликнуться на вакансию, нужно войти или зарегистрироваться": "Vakansiyaga taklif yuborish uchun tizimga kirishingiz yoki ro'yxatdan o'tishingiz kerak",
  "Фильтры": "Фильтры",
  "Разместите вакансию": "Разместите вакансию",
  "чтобы кандидаты сами могли найим вас": "чтобы кандидаты сами могли найим вас",
  "Применить": "Saqlash",
  "подходящих вакансий": "mos keluvchi vakansiyalar",
  "Сортировка": "Saralash",
  "C полследнего размещения": "E'lon qilinganidan boshlab",
  "откликов": "takliflar",
  "просмотров вакансии": "ko'rishlar soni",
  "показов вакансии": "показов вакансии",
  "Вам подойдут эти вакансии": "Bu vakansiyalar sizga mos keladi",
  "до": "gacha",
  "от": "dan",
  "До": "gacha",
  "От": "dan",
  "Подробнее": "Batafsil",
  "Нет компании": "Tashkilot mavjud emas",
  "сотрудников": "xodimlar",
  "О компании": "Tashkilot haqida",
  "Восстановить": "Tiklash",
  "Опубликовать": "E'lon qilish",
  "Опубликовано": "E'lon qilingan",
  "Хорошо": "Yaxshi",
  "Новые": "Yangilar",
  "Просмотренные": "Ko'rilganlar",
  "Aктивные": "Aktivlar",
  "Пожалуйста, введите новый пароль для учетной записи": "Foydalanuvchi uchun yangi parolni kiriting",
  "Адрес": "Manzil",
  "Требуемый опыт работы": "Talab qilingan ish tajribasi",
  "Вы отправили отклик на эту вакансию": "Siz bu vakansiyaga taklif yuborgansiz",
  "Работа в Узбекистане. Открытые вакансии по категориям.": "O'zbekistonda ish izlash. Kategoriyalar bo'yicha vakansiyalar",
  "Например, организация конференцей": "Masalan, konferensiyalarni tashkillash",
  "Готов выйти через 2 недели": "2 haftadan keyin chiqishga tayyor",
  "Обновлено": "Yangilangan",
  "Поиск по компаниям": "Kompaniyalar bo'yicha izlash",
  "Все компании": "Barcha kompaniyalar",
  "По отраслям": "Sohalar bo'yicha",
  "Поиск компании": "Kompaniyani izlash",
  "Найти": "Topish",
  "Просмотры": "Ko'rib chiqilgan",
  "Поднять вакансию": "Vakansiyani ko'tarish",
  "Закрыть вакансию": "Vakansiyani yopish",
  "Добавить вакансию": "Vakansiya qo'shish",
  "Кандидаты по вакансии": "Vakansiya bo'yicha nomzodlar",
  "Указать ещё один язык": "Yana bir til qo'shish",
  "Указать язык": "Til bilish darajasini ko'rsatish",
  "Указать другое контактное лицо": "Aloqa uchun boshqa shaxsni ko'rsatish",
  "Можно и без резюме (только имя,  фамилия и контакты)": "Rezyumesiz ham mumkin(faqat ism, familiya va aloqa ma'lumotlari",
  "Посмотреть все категории": "Barcha kategoriyani ko'rsatish",
  "Требования к  соисталелю": "Ish izlovchiga talablar",
  "Выберите": "Tanlang",
  "сум": "so'm",
  "Сумма": "Miqdor",
  "suitable_vacancies":"Yana {param} mos vakansiyalar",
  "Формат файла должно быть один из следующих (JPG, JPEG, PNG).":"Формат файла должно быть один из следующих (JPG, JPEG, PNG).",
  "Размер файла не должно превышать 2мб.":"Размер файла не должно превышать 2мб.",
  "Поиск сотрудников в Узбекистане":"O'zbekistonda xodimlarni topish",
  "Соискателю":"Ish izlovchilarga",
  "Кандидаты по категориям":"Kategoriya bo‘yicha kandidatlar",
  "Поиск кандидатов по регионам":"Hududlar bo'yicha kandidatlar",
  "Найти сотрудников в Узбекистане":"O'zbekistonda xodim izlash",
  "На данной странице вы найдете информацию о предложениях компаний. Предложения содержат цены, подробное описание и контактную информацию работодателей, работающих на рынке Узбекистана":"Ushbu sahifada siz kompaniyalar takliflari haqida ma'lumot topasiz. Takliflar narxlari, batafsil tavsifi va O'zbekiston bozorida faoliyat yuritayotgan ish beruvchilarning aloqa ma'lumotlarini o'z ichiga oladi",
  "Вакансии в Ташкенте":"Tashkent bo‘yicha ish o‘rinlari",
  "Вакансии компаний":"Kompaniya vakansiyalari",
  "Удобный поиск, актуальная информация - описание, фото, адреса и телефоны":"Qulay qidiruv, dolzarb ma’lumotlar - tavsif, fotosuratlar, manzillar va telefonlar",
  "найдено вакансий":"{param}ta vakansiya topildi",
  "Введите свой номер телефона": "Telefon raqamingizni kiriting",
  "и":"va",
  "Регион": "Hudud",
  "Похожие вакансии": "O'xshash vakansiyalar",
  "Все вакансии": "Barcha vakansiyalar",
  "Чтобы откликнуться на вакансию, нужно войти или зарегистрироваться.": "Bu imkoniyatdan foydalanishingiz uchun ish izlovchi sifatida tizimga kirishingiz kerak.",
  "Вакансия успешно опубликовано!": "Vakansiya muvaffaqiyatli joylashtirildi!",
  "Успешно": "Muvaffaqiyatli",
  "Это займет 3 минуты, и система сама найдет подходящие для вас  вакансии": "Bunga 3 daqiqa vaqt talab etiladi va tizim Sizga mos vakansiyalarni topadi",
  "На данной странице вы найдете информацию о предложениях компаний . Предложения содержат цены, подробное описание и контактную информацию работодателей, работающих на рынке Узбекистана": "Ushbu sahifada Siz kompaniyalar takliflari haqida ma'lumot topasiz. Takliflarda narxlar, batafsil tavsif va O'zbekiston bozorida ishlaydigan ish beruvchilarning aloqa ma'lumotlari mavjud.",
  "Работа в Ташкенте и городах Узбекистана. Подбор персонала и вакансии. Поиск работы в Узбекистане": "Toshkent va O'zbekiston shaharlarida ishlash. Xodimlarni va vakansiyalarni tanlash. O'zbekistonda ish topsih",
  "Работа в Ташкенте и городах Узбекистана":"Toshkent va O'zbekiston shaharlarida ishlash",
  "Нет подходящих вакансий": "Mos vakansiyalar yo‘q",
  "Здесь будут находиться ваши чаты с работодателями": "Ish beruvchilar bilan suhbatlaringiz shu yerda bo'ladi",
  "Сообщение": "Xabar",
  "Отправить": "Yuborish",
  "Сайт": "Sayt",
  "Не указана": "Ko'rsatilmagan",
  "Посмотреть все отрасли": "Barcha sohalarni ko'rish",
  "Логотип": "Logotip",
  "Чем занимается компания, ее миссия, успехи, перспективы, профессионального и карьерного роста, мотивационные программы, премиущества работы в компании": "Kompaniya nima qiladi, uning vazifasi, muvaffaqiyatlari, istiqbollari, professional va martaba o'sishi, motivatsion dasturlar, kompaniyada ishlashning afzalliklari",
  "Прямой работодатель": "To'g'ridan-to'g'ri ish beruvchi",
  "Кадровое агентство": "Ishga qabul qilish agentligi",
  "Ваша эл. почта": "Sizning elektron pochtangiz",
  "Перейти на эл. почту": "Elektron pochtaga o'tish",
  "Новый пароль еще раз": "Yangi parolni qayta kiriting",
  "Войти по коду": "Kod bilan kirish",
  "Подтверждение": "Tasdiqlash",
  "Повторно отправить код": "Kodni qayta yuborish",
  "Отправить повторно": "Qayta yuborish",
  "Письмо отправлено!": "Xat yuborildi!",
  "Чтобы восстановить пароль, откройте письмо, которое мы только что отправили на эл. почту": "Parolingizni tiklash uchun, Sizgning  elektron pochtangizga yuborilgan xatni oching.",
  "и нажмите в нем на кнопку “Восстановить пароль”": "va “Parolni tiklash” tugmasini bosing",
  "Новый пароль": "Yangi parol",
  "Назад": "Orqaga",
  "Еще": "Yana",
  "Выбрать несколько": "Bir nechtasini tanlash",
  "Выбрать один": "Bittasini tanlash",
  "без указания":"belgilanmagan",
  "Мои сохраненные резюме":"Mening saqlangan rezyumelarim",
  "Отклик на вакансию": "Ishga yuborilgan javob",
  "Cопроводительный текст (не обязательно)":"Qo'shimcha matn (shart emas)",
  "Для отклик на вакансию":"Vakansiyaga javob yoborish uchun",
  "Ваше резюме неполное":"Sizning rezyumeingiz to'liq emas",
  "Пожалуйста, заполните свое резюме полностью":"Iltimos, rezyumengizni toʻliq toʻldiring",
  "Заполнить резюме":"Rezyumeni to'ldiring",
  "Чем выше ваш процент, тем больше работодателей видят ваш профиль. Постарайтесь заполнить профиль свыше 80% чтобы получать максимальное количество предложений":"Sizning foizingiz qanchalik yuqori bo'lsa, ish beruvchilar Sizning profilingizni shunchalik ko'p ko'radi. Maksimal takliflar sonini olish uchun profilingizni 80% dan ko'proq to'ldirishga harakat qiling",
  "Данные успешно сохранены!":"Maʼlumotlar muvaffaqiyatli saqlandi!",
  "появлений в результатах поиска в неделю":"Haftada {param} ta chiqish qidiruv natijalarida",
  "Просмотрен":"Koʻrilgan",
  "Не просмотрен":"Koʻrilmagan",
  "Доставлен":"Yetkazilgan",
  "Удалить":"O'chirish",
  "Нет отклики":"Yuborilgan javob yo‘q",
  "В этом блоке вы можете добавить информации о вас":"Ushbu blokda o'z haqingizda ma'lumot qo'shishingiz mumkin",
  "Укажите дополнительное образование":"Qo'shimcha ta'limni belgilang",
  "Добавить еще":"Yana qoʻshish",
  "В этом блоке вы можете добавить дополнительное образование":"Ushbu blokda Siz qo'shimcha ta'lim qo'shishingiz mumkin",
  "В этом блоке вы можете добавить желаемый вид занятости.":"Ushbu blokda Siz bandlik turingizni qo'shishingiz mumkin",
  "Вы уверены, что хотите удалить этот запись?":"Haqiqatan ham bu ma'lumotni oʻchirib tashlamoqchimisiz?",
  "Внимание":"Diqqat",
  "Да":"Ha",
  "Нет":"Yo'q",
  "Запись успешно удалена":"Ma'lumot muvaffaqiyatli oʻchirildi",
  "Специализация":"Mutaxassislik",
  "Регион поиска работы":"Ish topish hududi",
  "Весь город":"Butun shahar",
  "Укажите уровень владения компьютером":"Kompyuter bilish darajasini belgilang",
  "Нет личный автомобиль":"Shaxsiy avtomobil mavjud emas",
  "Местоположение учебного заведения":"Ta'lim muassasasining manzili",
  "Должность":"Lavozim",
  "Местоположение":"Manzil",
  "Обязанности и достижения":"Mas'uliyat va yutuqlar",
  "Наименование организации":"Tashkilotning nomi",
  "Местоположение (страна)":"Manzil (mamlakat)",
  "Местоположение (регион)":"Manzil (hudud)",
  "Начало работы":"Ishning boshlanishi",
  "Завершение работы":"Ishning tugashi",
  "Семейное положение":"Oilaviy ahvol",
  "Семейный статус":"Oilaviy holat",
  "Кол-во детей":"Bolalar soni",
  "В этом блоке вы можете указать семейное положение":"Ushbu blokda Siz oilaviy holatingizni belgilashingiz mumkin",
  "Указать":"Belgilash",
  "Язык":"Til",
  "Уровень владения":"Bilish darajasi",
  "Персональная информация":"Shaxsga doir ma'lumotlar",
  "ФИО":"FIO",
  "Дата рождение":"Tug'ilgan sanasi",
  "Серия и номер паспорта":"Pasport raqami",
  "ПИНФЛ":"JSHSHIR",
  "Фамилия":"Familiya",
  "Имя":"Ism",
  "Отчество":"Otasining ismi",
  "Желаемая должность":"Istalgan lavozim",
  "Мои навыки":"Mening ko'nikmalarim",
  "Укажите свои навыки":"O'z ko'nikmalaringizni belgilang",
  "Кандидаты  по категориям":"Kategoriyalar bo'yicha nomzodlar",
  "Поиск кандидатов по городам":"Nomzodlarni shahar bo'yicha topish",
  "response send": "Vakansiyaga yuborilgan javob",
  "Здесь хранятся соискатели, которые вас заинтересовали. Чтобы добавить соискателя в избранное посмотрите кандидатов и  нажмите на сердечку": "Sizni qiziqtirgan ish izlovchilar shu yerda saqlanadi. Ish izlovchini saralanganlarga qo'shish uchun, nomzodlarni ko'rib chiqib, 'yurakcha' tugmasiga bosing.",
  "Посмотреть кандидатов": "Nomzodlarni ko'rish",
  "Работа в Ташкенте": "Bosh sahifa",
  "Адрес поиска работы": "Ish qidirish manzili",
  "Код отправлен на ваш номер в смс": "Kod sms orqali quyidagi raqamga yuborildi:",
  "Введите код": "Kodni kiriting",
  "Количество символов должно быть максимум": "Belgilar soni ko'pi bilan {param} ta bo'lishi mumkin",
  "Количество символов должно быть": "Belgilar soni {param} ta bo'lishi kerak",
  "Количество символов должно быть минимум": "Belgilar soni kamida {param} ta bo'lishi kerak",
  "Зарегистрируйтесь и найдите работу": "Ro'yxatdan o'ting va izlagan ishingizni toping",
  "Напишите фамилия": "Familiyangizni kiriting",
  "Напишите имя": "Ismingizni kiriting",
  "Какую вы ищете работу": "Qanday ish izlayapsiz",
  "Например, учитель": "Masalan, o'qituvchi",
  "Где вы ищете работу": "Qayerdan ish izlayapsiz",
  "Мужчина": "Erkak",
  "Женщина": "Ayol",
  "Город(район)": "Qaysi shahar(tuman)dan izlayapsiz",
  "Чтобы сохранить вакансию, нужно войти в систему как соискатель": "Vakansiyani saqlash uchun, ish izlovchi sifatida tizimga kirishingiz kerak",
  "Для отклика на данную вакансию ваш профиль должен быть заполнен информацией": "Bu vakansiyaga taklif yuborishingiz uchun profilingizni to'ldirishingiz lozim.",
  "Чтобы откликнуться на эту вакансию, ваше резюме должно быть выше 70 процентов.  Заполните оставшиеся данные: ": "Bu vakansiyaga taklif yuborishingiz uchun profilingiz 70% dan yuqoriga to'ldirilishi kerak. Quyidagi bloklarni to'ldiring:",
  "Заполнить профиль": "Profilni to'ldirish",
  "Весь регион": "Butun viloyat",
  "Требования:": "Talablar:",
  "Условия работы:": "Ish sharoitlari:",
  "Обязанности:": "Majburiyatlar:",
  "Город (район) работы": "Ish shahri (tuman)",
  "Не указывать контактное лицо (обращение только через отклик)": "Aloqa uchun ma'lumotlar ko'rsatilmasin(faqat taklif yuborish orqali)",
  "Не указывать": "Belgilanmagan",
  "Редактировать вакансию": "Vakansiyani tahrirlash",
  "год" : "yil",
  "года": "yil",
  "лет": "yil",
  "месяц": "oy",
  "месяца": "oy",
  "месяцев": "oy",
  "age год" : "yosh",
  "age года": "yosh",
  "age лет": "yosh",
  "Контактный номер": "Aloqa uchun raqam",
  "Введите E-mail": "E-mail manzilini kiriting",
  "Введите контактный номер": "Aloqa uchun raqam kiriting",
  "База кандидатов доступна только зарегистрированным работодателям.": "Kandidat haqida batafsil ma'lumotni ko'rish uchun ish beruvchi sifatida ro'yxatdan o'tishingiz kerak.",
  "О себе": "O'zim haqimda",
  "Найти сотрудника" : "Xodim topish",
  "Добавить фото": "Fotosurat qo'shish",
  "Работаю тут по настоящее время": "Hozir shu yerda ishlayapman",
  "Опишите, что вы делали на работе": "Ishda nima qilganingizni tavsiflab bering",
  "Отмечайте понравившиеся вам вакансии и смотрите их здесь.": "Vakansiyalarni saqlab qo'ying va shu yerda kuzatib boring.",
  "Нет избранных": "Saqlanganlar yo‘q",  
  "Мы отправили код подтверждение на ваш E-mail": "Biz sizning E-mail manzilingizga tasdiqlash kodini jo'natdik",
  "Пожалуйста, проверьте свой E-mail": "Iltimos, E-mail manzilingizni tekshiring",
  "У вас пока нет опубликованных вакансий. Опубликуйте вакансию и находите лучших кандидатов!": "Hozircha sizda e'lon qilingan vakansiyalar yo‘q. Vakansiyalaringizni e'lon qiling va eng yaxshi xodimlarni toping!",
  "Сообщений пока нет": "Hozircha xabarlar yo‘q",
  "Здесь будут находиться ваши чаты с Соискателями": "Bu yerda sizning nomzodlar bilan yozishmalaringiz saqlanadi",
  "Удалить фото": "Suratni o'chirish",
  "Добавлено в сохраненное": "Saqlanganlarga qo'shildi",
  "Удалено из сохраненных": "Saqlanganlardan o'chirildi",
  "Выбрать": "Tanlang",
  "Закрыть":"Yopish",
  "Задать вопрос":"Savol yo'llash",
  "Нет соединения с сервером, пожалуйста проверьте подключение к интернету":"Server bilan aloqa yo‘q, internetga ulanganligingizni tekshiring",
  "Районы": "Tumanlar",
  "Вакансия будет отправлена в архив, в будущем вы можете восстановить вакансию для повторной публикации.":"Vakansiya arxivga yuboriladi, kelajakda siz vakansiyani qayta tiklashingiz mumkin.",
  "Переместить в архив":"Arxivga o'tkazish",
  "Вакансия успешно отправлено в архив!":"Vakansiya arxivga ko'chrildi!",
  "Пожалуйста, выберите хотя бы одно значение":"Iltimos, yuqoridagilardan hech bo'lmaganda birini tanlang",
  "Пожалуйста, выберите одно значение":"Iltimos, yuqoridagilardan birini tanlang",
  "Публикуйте вакансии, получайте отклики и общайтесь с кандидатами": "Vakansiyalaringizni e'lon qiling, takliflar oling va nomzodlar bilan muloqotga kirishing",
  "Вы можете указать дополнительную информацию о компании, загрузить лого и добавить описание": "Tashkilotingiz haqida qo'shimcha ma'lumotlar kiritishingiz va tashkilot logotipini yuklashingiz mumkin",
  "Вакансии<br> и отклики": "Vakansiyalar<br> va takliflar",
  "Информация<br> о компании": "Tashkilot<br> haqida ma'lumot",
  "Скачать резюме":"Yuklab olish",
  "Настройки профиля":"Profil sozlamalari",
  "Удаление аккаунта":"Profilni o'chirish",
  "Вы уверены, что хотите удалить свой профиль?":"Haqiqatan ham o'z profilingizni oʻchirmoqchimisiz?",
  "Код подтверждение":"Tasdiqlash kodi",
  "Посмотреть вакансии":"Vakansiyalarni ko'rish",
  "SMS отправлено на номер": "Kod {param} raqamiga sms tarzida yuborildi",
  "Сохраненные вакансии":"Saqlangan vakansiyalar",
  "Пожалуйста, введите URL":"Iltimos, URL standartida kiriting",
  "У вас нет архивные вакансий": "Sizda arxivlangan vakansiyalar yo‘q",
  "У вас нет неопубликованных вакансий": "Sizda e'lon qilinmagan vakansiyalar yo‘q",
  "Подтвердите ваше согласие": "Roziligingizni tasdiqlang",
  "Наверх":"Sahifa yuqorisiga",
  "Вернуться к регистрации": "Ro'yxatdan o'tishga qaytish",
  "Создано":"Yaratilgan sana",
  "У вас нет заархивированных вакансий":"Sizda arxivlangan vakansiya yo‘q",
  "Вакансия закрыта":"Vakansiya yopilgan",
  "У вас <b>1</b> непрочитанных сообщений": "Sizda <b>{param}</b>ta o'qilmagan xabar mavjud",
  "У вас есть непрочитанное сообщение": "Sizda o'qilmagan xabar mavjud",
  "Ваш статус 'Не ищу работу'":"Sizning statusingiz 'Ish izlamayapman'",
  "Чтобы откликнуться на вакансию, пожалуйста, поменяйте свой статус на 'Ищу работу'":"Ushbu vakansiyaga taklif yuborish uchun, iltimos, statusingizni 'Ish izlayapman'ga o‘zgartiring",
  "Сумма должно быть больше, чем начальная сумма":"Ushbu miqdor boshlang‘ich miqdordan ko‘p bo‘lishi kerak",
  "Текущий пароль": "Hozirgi parol",
  "Не ищет работу": "Ish izlamayapti",
  "Деактивировать аккаунт":"Akkauntni deaktivatsiya qilish",
  "Вы уверены, что хотите деактивировать свой профиль?":"Haqiqatan ham o‘z profilingizni deaktivasiya qilmoqchimisiz?",
  "Самый быстрый и простой способ найти работу — создать профиль на нашем сайте!":"Ish topishning eng tez va oson yo‘li - saytimizda profil yaratish!",
  "Создайте профиль и система сама найдет вам работу.":"Profil yarating va tizimning o‘zi Sizga ish topib beradi.",
  "Удалённый аккаунт":"O'chirilgan hisob",
  "Вакансия не найдена":"Vakansiya topilmadi",
  "день":"kun",
  "дня":"kun",
  "Фрилансер":"Frilanser",
  "Выберите язык":"Til tanlang",
  
  "ish.uz - это смарт платформа для быстрого поиска работы": "<b>ish.uz</b> - bu tezkor ish topish<br> smart platformasi",
  "Cмарт платформа для быстрого поиска работы": "Tezkor ish topish smart platformasi",
  "Как работает ISH.UZ":"ISH.UZ qanday ishlaydi",
  "Заполните профиль, указав информацию о себе, уровне образования и опыте работы":"<b>Profilingizni</b> o'zingiz, ta'lim darajangiz va ish tajribangiz haqidagi ma'lumotlar bilan <b>to'ldiring</b>",
  "Система в автоматическом режиме подберет самые подходящие для вас  вакансии":"Tizim avtomatik ravishda siz uchun <b>eng mos bo'lgan</b> ish o'rinlarini tanlaydi",
  "Получайте уведомления о новых вакансиях и предложения от компаний":"Yangi ish o'rinlari va <b>kompaniyalardan takliflar</b> haqida bildirishnomalarni oling",
  "5 минут и вы получите доступ к вакансиям лучших компаний":"<span>5 daqiqa</span> va siz eng yaxshi kompaniyalarning vakansiyalariga kirish huquqiga ega bo'lasiz",
  "Мы используем технологии использования нейросетей и машинного обучения для того, чтобы вы нашли лучшую работу как можно быстрее.":
  "Siz eng yaxshi ishni imkon qadar tezroq topishingiz uchun biz neyron tarmoq va mashinaviy o'qitish texnologiyalaridan foydalanamiz.",
  "Войти в профиль":"Profilga kirish",
  "Замечательно!":"Ajoyib!",
  "Давайте перейдем к новым возможностям!":"Keling, yangi imkoniyat sari yo'l olaylik!",
  "Мы на полпути!":"Yarim yo'lni bosib o'tdik!",
  "Пропустить":"Oʻtkazib yuborish",
  "Добавить язык":"Til qo'shish",
  "Поздравляем!":"Tabriklaymiz!",
  "Профиль заполнен!":"Profil to'ldirildi",
  "В каком регионе ищете работу?":"Qaysi hududdan ish izlayapsiz?",
  "Какую работу ищете?":"Qanday ish izlayapsiz?",
  "Добавить учебное заведение":"Muassasa qo'shish",
  "Добавить опыт работы":"Ish tajribasi qo'shish",
  "Имеется личный автомобиль?":"Shaxsiy avtomobil bormi?",
  "Нет избранных вакансий":"Saqlangan vakansiyalar yo‘q",
  "Вперёд":"Boshladik",
  "Пожалуйста уточните":"Iltmos, aniqlashtiring",
  "Возможно вы имели в виду":"Quyidagilarni nazarda tutdingizmi",
  "У вас есть опыт работы?":"Ish tajribaga egamisiz?",
  "Расположение места работы":"Ish manzili",
  "Перейти в профиль":"Profilga o`tish",
  "Профиль":"Profil",
  "Изменить":"Oʻzgartirish",
  "Добавить желаемый уровень заработной платы":"Istalgan ish haqi darajasi qo'shish",
  "Добавить знание языков":"Til qo'shish",
  "Добавить ключевые навыки":"Ko'nikma qo'shish",
  "Cкопировать":"Nusxalash",
  "Скопирован":"Nusxalandi",
  "Публичный":"Ommaviy",
  "Скрытый":"Yashirin",
  "Чат":"Chat",
  "Создайте вакансию в системе":"<b>Создайте вакансию</b> в системе",
  "Система автоматически подберет и оценит подходящих кандидатов на вакансию":"<b>Система автоматически подберет</b> и оценит подходящих кандидатов на вакансию",
  "Проведите все этапы отбора с помощью удобных инструментов":"Проведите все этапы отбора с помощью <b>удобных инструментов</b>",
  "Ссылка":"Havola",
  "Настройте конфиденциальность вашего профиля":"Profilingiz maxfiyligini sozlang",
  "Мобильный телефон:":"Mobil telefon:",
  "Работодатели не могут найти ваше резюме в системе поиска ish.uz.  Это не повлияет на предыдущие заявки и не помешает компаниям, которым вы ответили, связаться с вами.":"Ish beruvchilar sizning rezyumeingizni ish.uz qidiruv tizimida topa olmaydi. Bu avvalgi yuborilgan xabarlarga ta'sir qilmaydi yoki siz javob yuborgan kompaniyalar siz bilan bog'lanishiga to'sqinlik qilmaydi.",
  "Подробнее о конфиденциальности вашего профиль":"Profilingiz maxfiyligi haqida ko'proq bilib oling",
  "Работодатели, которые ищут кандидатов, могут найти ваше резюме и информацию о профиле на сайте ish.uz в соответствии с нашими условиями":"Nomzodlarni qidirayotgan ish beruvchilar ish.uz saytida bizning shartlarimizga muvofiq rezyume va profil ma’lumotlarini topishlari mumkin",
  "Молодец!":"Barakalla!",
  "Профил тўлдириш":"Profil to‘ldirish",
  "Сертификаты":"Sertifikatlar",
  "Добавить сертификат":"Sertifikat qo'shish",
  "Наименование":"Nomi",
  "Дата получения":"Olgan sanasi",
  "Без опыта работы":"Ish tajribaga ega emas",
  "Добавить о себе":"O'zingiz haqingizda maʼlumot qo'shish",

  "Добавить уровень образования":"Ta'lim ma'lumoti qo'shish",
  "Добавить уровень владения компьютером": "Kompyuterdan foydalanish ko'nikmasi qo'shish",
  "Добавить Email":"Email qo'shish",
  "Подтверждать":"Tasdiqlash",
  "Отправить код повторно можно через":"{param} dan keyin kodni qayta yuborish mumkin",
  "Отправить код еще раз": "Kodni qayta yuboring",
  "Изменить номер":"Raqamni o'zgartirish",
  "Отклик успешно отправлен!":"Taklif muvaffaqiyatli yuborildi!",
  "Вы пока не оставляли отклика ни к одной вакансии.":"Siz hali biror vakansiyaga taklif yubormagansiz.",
  "Навык":"Ko'nikma",
  "Резюме не найдено": "Rezyume topilmadi",
  "Не требуется личный автомобиль":"Shaxsiy avtomobil talab qilinmaydi",
  "Опубликовать на {portal}":"<b>{portal}</b> da e'lon qilish",
  "Опубликовано на portals": "Quyidagilarda e'lon qilingan ",
  "Кандидаты":"Nomzodlar",
  "Отчеты":"Hisobotlar",
  "Кадровый резерв":"Kadrlar zaxirasi",
  "Черный список":"Qora ro‘yxat",
  "Пользователи":"Foydalanuvchilar",
  "Посмотреть вакансию":"Vakansiyani ko‘rish",
  "Режим доски":"Doska rejimi",
  "Режим списка":"Ro‘yxat rejimi",
  "Этапы":"Bosqichlar",
  "Дополнительные этапы":"Qo‘shimcha bosqichlar",
  "Наши этапы":"Bizning bosqichlarimiz",
  "Добавить этап":"Bosqich qo‘shish",
  "Открыть воронку":"Voronkani ochish",
  "Стандартные этапы":"Standart bosqichlar",
  "Предложения ISH.UZ":"ISH.UZ dan takliflar",
  "Загрузить резюме":"Rezyume yuklash",
  "Загрузить фото":"Fotosurat yuklash",
  "Желаемый уровень ЗП":"Istalgan ish haqi darajasi",
  "Добавить кандидата":"Nomzod qo'shish",
  "Уровень образования":"Ta'lim darajasi",
  "Добавить образование":"Ta'lim ma'lumoti qo'shish",
  "Добавить ещё образование":"Yana ta'lim ma'lumoti qo'shish",
  "Фото":"Foto",
  "Дата добавления":"Qo'shilgan sana",
  "Кем добавлен":"Kim qo'shgan",
  "Скачать в Excel":"Excel ga yuklab olish",
  "Срок закрытия вакансии":"Vakansiyaning yopilish sanasi",
  "Мы отправили ссылку активации на почту {mail}":"Faollashtirish havolasini sizning quyidagi elektron pochtangizga yubordik {mail}",
  "Кандидаты не добавлены на этот этап":"Nomzodlar ushbu bosqichga qo'shilmagan",
  "У вас нет активных вакансий":"Sizda faol vakansiyalar yo‘q",
  "Комментарий":"Izoh",
  "Добавлен вручную":"Qo'lda qo'shilgan",
  "Введите текст":"Matn kiriting",
  "Логин":"Login",
  "Вы уверены, что хотите удалить этот email?":"Haqiqatan ham ushbu email ni oʻchirmoqchimisiz?",
  "Другая почта":"Boshqa pochta",
  "Добавить почту":"Pochta qo'shish",
  "Сервер почты":"Pochta serveri",
  "Порт":"Port",
  "Безопасное соединение":"Xavfsiz ulanish",
  "Введите логин":"Loginni kiriting",
  "Введите email":"Email kiriting",
  "У вас еще не добавлен email отправителя":"Sizda hali jo‘natuvchining emaili qo‘shilmagan",
  "На почту":"Pochta",
  "ISH.UZ чат":"ISH.UZ chat",
  "Телеграм":"Telegram",
  "Открыть в телеграме":"Telegramda ochish",
  "Новое сообщение":"Yangi xabar",
  "Тема письма":"Xatning mavzusi",
  "Кому":"Kimga",
  "От кого":"Kimdan",
  "sent_time":"Yuborilgan vaqti",
  "Оценка профиля":"Profil bahosi",
  "г.":"y.",
  "Высшее":"Oliy",
  "Пароль от почты":"Pochtaning paroli",
  "Создан":"Yaratilgan",
  "Нет информации":"Ma'lumot yo'q",
  "Почта":"Pochta",
  "Кандидат не найден":"Nomzod topilmadi",
  "Введите должность":"Lavozimni kiriting",
  "Причина отказа":"Rad etish sababi",
  "Способ уведомления кандидата":"Nomzodni xabardor qilish usuli",
  "Редактировать кандидата":"Nomzodni tahrirlash",
  "Нет данных":"Maʼlumot yoʻq",
  "У вас есть несохраненные данные. Хотите продолжить?":"Sizda saqlanmagan maʼlumotlar mavjud. Davom etishni xohlaysizmi?",
  "Соответствие":"Mosligi",
  "Скоринг":"Skoring",
  "Добавить email отправителя": "Jo'natuvchi uchun email qo'shish",
  "Конверсия": "Konversiya",
  "Добавить заказчика": "Buyurtmachi qo`shish",
  "Добавить рекрутера": "Rekruter qo`shish",
  "Организация не зарегистрирована на этот адрес": "Ushbu <b>{param}</b> bilan tashkilot ro'yxatga olinmagan.Iltimos,quyidagi ma'lumotlarni kiriting.",
  "Поиск по базе<br> соискателей": "Nomzodlar bazasidan qidirish",
  "Находите лучших кандидатов из базы данных": "Nomzodlar bazasidan eng yaxshi nomzodni toping",
  "Введите в формате адрес@ящик.домен": "example@gmail.com formatida kiriting",
  "Восстановить пароль": "Parolni tiklash",
  "Почта для отправки писем":"Xabar jo`natish uchun pochta",
  "Удаленный профиль":"Oʻchirilgan profil",
  "Организация не зарегистрирована по данному адресу email.":"Ushbu <b>{email}</b> bilan tashkilot ro'yxatga olinmagan.",
  "Пожалуйста, введите следующую информацию.":"Iltimos, quyidagi ma'lumotlarni kiriting.",
  "Электронный адрес email не привязан ни к одной из компаний.":"<b>{email}</b> elektron pochtasi hech bir tashkilotga bog'lanmagan.",
  "Для регистрации новой компании заполните поля ниже:":"Yangi tashkilotni ro'yxatdan o'tkazish uchun quyidagi ma'lumotlarni kiriting:",
  "На e-mail email отправлено письмо с кодом доступа.":"<b>{email}</b> elektron pochtaga tasdiqlash kodi yuborildi.",
  "Пожалуйста, проверьте электронную почти и введите код из письма.":"Iltimos, elektron pochtangizni tekshiring va xabardagi kodni kiriting.",
  "Чат с соискателями":"Nomzodlar bilan chat",
  "По вашим опубликованным вакансиям есть новые отклики":"E’lon qilingan vakansiyalaringizga yangi nomzodlar bor",
  "n отклик":"nomzod",
  "n отклика":"nomzod",
  "n откликов":"nomzod",
  "Не опубликовано":"E'lon qilinmagan",
  "Опубликованные":"E'lon qilinganlar",
  "Не опубликованные":"E'lon qilinmaganlar",
  "Статус":"Status",
  "Архив":"Arxiv",
  "соискателей":"nomzodlar",
  "У вас есть новые отклики":"Sizda yangi javoblar bor",
  "У вас нет новых откликов":"Sizda yangi javoblar yo'q",
  "Вы не можете добавить кандидата на эту доску":"Siz ushbu ustunga nomzod qo'sha olmaysiz",

  "Осталось {n} день доступа": "Ruxsat tugashiga {n} kun qoldi",
  "Осталось {n} дня доступа": "Ruxsat tugashiga {n} kun qoldi",
  "Осталось {n} дней доступа": "Ruxsat tugashiga {n} kun qoldi",
  "Выберите подходящий тарифный план для доступа к базе соискателей":"Nomzodlar bazasiga ulanish uchun o'zingizga mos tarifni tanlang",
  "Рекомендуемый":"Tavsiya etiladi",
  "Доступ к базе соискателей":"Nomzodlar bazasiga ulanish",
  "дней":"kun",
  "Вы уже достигли этот лимит":"Sizning limitingiz to'lgan",
  "c функцией “Автоподбор”":"“Avtomatik tavsiya” funksiyasi bilan",
  "Количество вакансий":"Vakansiyalar soni",
  "Вы выбрали":"Tanlangan tarif",
  "Доступ к базе данных кандидатов":"Nomzodlar bazasiga ulanish",
  "Срок":"Muddati",
  "Автоподбор кандидатов":"Nomzodlarni avtomatik tavsiya etish",
  "Наша алгоритмы подберут наилучших кандидатов, вам останется только выбрать":"Bizning algoritmlarimiz eng yaxshi nomzodlarni tavsiya etadi, siz faqat tanlashingiz kerak",
  "Бесплатно при покупке доступа к базе":"Bonus",
  "У вас уже существует не оплаченный счет на этот тариф":"Sizda bu tarif uchun toʻlanmagan hisob-faktura mavjud",
  "Пожалуйста оплатите":"Iltimos to'lang",
  "Дата создание":"Yaratilgan sanasi",
  "Общая сумма":"Umumiy summa",
  "Не оплачен":"To'lanmagan",
  "Оплачен":"To'langan",
  "Оплатить через Payme":"Payme orqali to'lash",
  "История оплаты":"To'lovlar tarixi",
  "Виберите способ оплаты":"To'lov usulini tanlang",
  "Оплата производится через платежную систему Payme":"To'lov Payme to'lov tizimi orqali amalga oshiriladi",
  "Оплатить":"To'lash",
  "Оплата производится через банковский счет":"To'lov bank hisob raqami orqali amalga oshiriladi",
  "Сформировать счет":"To'lovni shakllantirish",
  "Расчетный счет":"Hisob raqami",
  "Банк":"Bank",
  "МФО":"Bank kodi(MFO)",
  "ИНН":"INN",
  "ОКЭД":"OKED",
  "Рег.код НДС":"QQS ro'yxatga olish kodi",
  "Тариф":"Tarif",
  "Дата начало":"Boshlangan sanasi",
  "Дата окончание":"Tugash sanasi",
  "Дата оплаты":"To'lov sanasi",
  "Способ оплаты":"To'lov turi",
  "Посмотреть":"Ko'rish",
  "Исполнитель":"Ijrochi",
  "Эл.почта":"Email",
  "Список заказанных услуг":"Buyurtma qilingan xizmatlar ro'yxati",
  "Описание":"Tavsif",
  "Кол-во":"Soni",
  "Стоимость":"Narxi",
  "Размещение вакансий":"Vakansiyalar soni",
  "Итого к оплате":"Jami summa",
  "Не забудьте оплатить счет в течение 1-го дня.":"To'lovni 1 kun ichida to'lashni unutmang.",
  "После формирования счета на оплату вы сразу сможете получить доступ к базе соискателей и функционалу автопоиска.":"To'lov uchun hisob-fakturani yaratganingizdan so'ng, siz darhol nomzodlar bazasiga kirishingiz va “Avtomatik tavsiya” funksiyasidan foydalanishingniz mumkin bo'ladi.",
  "Скачать счет":"Hisobni yuklab olish",
  "Не ограничено":"Cheklanmagan",
  "Счет на оплату":"To'lov hisobi",

  "Электронная почта":"Emailingizni kiriting",
  "База кандидатов":"Kandidatlar bazasi",
  "Сохранить вакансию":"Vakansiyani saqlash",
  "Мои кандидаты":"Mening nomzodlarim",
  "Контактный телефон":"Telefon raqami",
  "Оплаченный период":"To'langan davr",

  "Осталось {month} месяц доступа":"Ruxsat tugashiga {month} oy qoldi",
  "Осталось {month} месяца доступа":"Ruxsat tugashiga {month} oy qoldi",
  "Осталось {month} месяцев доступа":"Ruxsat tugashiga {month} oy qoldi",
  
  "Осталось {month} месяц и {n} день доступа":"Ruxsat tugashiga {month} oy va {n} kun qoldi",
  "Осталось {month} месяц и {n} дня доступа":"Ruxsat tugashiga {month} oy va {n} kun qoldi",
  "Осталось {month} месяц и {n} дней доступа":"Ruxsat tugashiga {month} oy va {n} kun qoldi",
  
  "Осталось {month} месяца и {n} день доступа":"Ruxsat tugashiga {month} oy va {n} kun qoldi",
  "Осталось {month} месяца и {n} дня доступа":"Ruxsat tugashiga {month} oy va {n} kun qoldi",
  "Осталось {month} месяца и {n} дней доступа":"Ruxsat tugashiga {month} oy va {n} kun qoldi",
  
  "Осталось {month} месяцев и {n} день доступа":"Ruxsat tugashiga {month} oy va {n} kun qoldi",
  "Осталось {month} месяцев и {n} дня доступа":"Ruxsat tugashiga {month} oy va {n} kun qoldi",
  "Осталось {month} месяцев и {n} дней доступа":"Ruxsat tugashiga {month} oy va {n} kun qoldi",
  "Продлить доступ":"Ruxsatni uzaytirish",
  "Купить доступ":"Ruxsatni sotib olish",
  "Нет доступа":"Kirishga ruxsat yo‘q",
  "Бесплатно":"Tekin",
  "Итого":"Jami",
  "Автоподбор":"Avtomatik tavsiya",
  "База кандидатов":"Nomzodlar bazasi",
  "Моя компания":"Mening kompaniyam",
  "Договора и счета":"Shartnomalar va to'lovlar",
  "Тарифы":"Tariflar",
  "Период":"Muddat",
  "Счета":"To'lovlar",
  "Договора":"Shartnomalar",
  "Выберите компанию":"Kompaniyani tanlang",
  "Выберите период":"Muddatni talnang",
  "Счет на оплату № invoice_number к договору № contract_number доступ ISH.UZ period price сум":
    "{contract_number}-sonli shartnoma bo'yicha ISH.UZ PRO dan <b>{period}</b>lik foydalanish huquqi uchun <b>{price}</b> so'mga  {invoice_number}-sonli hisob",
  "Счет на оплату № invoice_number доступ ISH.UZ period price сум":
    "ISH.UZ PRO dan <b>{period}</b>lik foydalanish huquqi uchun <b>{price}</b> so'mga  {invoice_number}-sonli hisob",
  "Договор № contract_number от contract_date года возмездное оказание услуг ISH.UZ":
    "ISH.UZ PRO saytida pulli xizmatlar uchun <b>{contract_date}</b> yildagi <b>{contract_number}</b>-sonli shartnoma",

  "Руководитель":"Rahbar",
  "Введите пароль":"Parolni kiriting",
  "Пока у вас не сформированы счета":"Hozircha sizda hisoblar yaratilmagan",
  "Уважаемый user":"Hurmatli {user}",
  "Мы предоставили вашей компании полный доступ к сайту не дожидаясь поступления оплаты. Доступ действует в течение days. Пожалуйста, не забудьте оплатить счет.":
    "Biz sizning kompaniyangizga to'lovni kutmasdan saytga to'liq kirish huquqini berdik. To'lovsiz kirish {days} davomida amal qiladi. Iltimos, hisobni to'lashni unutmang.",
  "Распечатать":"Chop etish",
  "Введите реквизиты организации":"Tashkilot rekvizitlarini kiriting",
  "ФИО руководителя":"Rahbar FIOsi",
  "Должность руководителя":"Rahbar lavozimi",
  "Действующего на основании":"Faoliyat asosi",
  "Создать договор":"Shartnoma yaratish",

  "Для получения доступа к базе кандидатов, подключите тариф.":"Nomzodlar bazasiga ruxsat uchun tarifni ulang.",
  "Тарифы от 290 000 сум.":"Tariflar 290 000 so'mdan.",
  "У вас нет доступа к базе кандидатов.":"Sizda nomzodlar bazasiga ruxsat yo'q.",
  "Пробный период":"Sinov muddati",
  "Оплатить доступ":"To'lash",
  "Сегодня последний день доступа":"Bugun kirish ruxsatining oxirgi kuni",
  "Выбрать тариф":"Tarifni tanlash",
  "Если у вас возникли вопросы, позвоните на номер +998 95 169-08-08":"Agar sizda savollar bo‘lsa, <a href='tel:+998951690808'>+998 95 169-08-08</a> raqamiga qo‘ng‘iroq qiling",
  "Телефон службы поддержки":"Qo'llab-quvvatlash telefon raqami",
  "Выберите тариф, чтобы открыть доступ к контактным данным кандидатов и использовать все возможности сервиса":"Nomzodlarning aloqa ma'lumotlarini ko'rish va tizimning imkoniyatlaridan to'liq fodaylanish uchun tarifni tanlang",
  "Поздравляем":"Tabriklaymiz",
  "Ваша оплата прошла успешно":"To'lovingiz muvafaqqiyatli o'tkazildi",
  "Теперь вам доступна все возможности сервиса":"Endi tizimning imkoniyatlaridan to'liq foydalanishingiz mumkin",
  "Перейти на главную страницу":"Bosh sahifaga o'tish",
  "Оплата не проведена":"To'lov amalga oshirilmadi",

  "Профиль заполнен на русском языке":"Profil rus tilida to'ldirilgan",
  "Профиль заполнен на узбекском языке":"Profil o'zbek tilida to'ldirilgan",
  "Запросить дополнительные данные":"Qo'shimcha ma'lumotlarni so'rash",
  "Выберите данные которые вы хотите запросить":"Qanday ma'lumotni so'ramoqchiligingizni tanlang",
  "Ваш запрос успешно отправлен!":"Sizinng so'rovingiz muvaffaqiyatli yuborildi",
  "Запросить информацию":"Ma'lumot so'rash",
  "Добавить к себе":"Nomzodlarimga qo'shish",
  "В кадровый резерв":"Kadrlar zaxirasiga qo'shish",
  "В черный список":"Qora ro'yxatga qo'shish",
  "Кандидат добавлен в кадровый резерв":"Nomzod kadrlar zaxirasiga qo'shilgan",
  "Уведомления": "Хабарномалар",
  "Получать уведомления о новых кандидатах на вакансии(отклики) (11:00)": "Vakansiyalarga yangi nomzodlar(otkliklar) haqida 11:00 da xabarnoma olish",
  "Получать уведомления о новых кандидатах на вакансии(отклики) (17:00)": "Vakansiyalarga yangi nomzodlar(otkliklar) haqida 17:00 da xabarnoma olish",
  "Активный": "Faol",
  "Неактивный": "Nofaol",
  "Сортировка по дате": "Sana bo'yicha saralash",
  "Сортировка по соответствию": "Mosligi bo'yicha saralash",
  "Сортировка по скорингу":"Skoring bo'yicha saralash",
  "Показывать только новые": "Faqat yangilarni ko'rsatish",
  "Время последнего входа":"Oxirgi kirgan vaqti",
  "Превышен лимит доступных устройств на этом аккаунте":"Ushbu akauntdagi qurilmalar limitidan oshib ketdi",
  "Вы можете удалить одно из нижеперечисленных устройств, чтобы войти":"Kirish uchun quyidagi qurilmalardan birini o'chirishingiz mumkin",
  "по":"gacha",
  "position_name в company_name": "<b>{company_name}</b> da <b>{position_name}</b>",
  "Есть личное авто":"Shaxsiy avtomobil mavjud",
  "Водительские права":"Haydovchilik guvohnomasi",
  "Предпочтительный возраст":"Mos keluvchi yosh",
  "Предпочтительный пол":"Vakansiya kimlarga mos",
  "эта информация только для автоподбора и не будет отображаться для соискателей":"ushbu ma'lumot nomzodlarni avtomatik saralash uchun ishlatiladi, ish izlovchilarga ko'rsatilmaydi",
  "до param_to":"{param_to} gacha",
  "от param_from до param_to":"{param_from} dan {param_to} gacha",
  "от param_from":"{param_from} dan",
  "ФИО контактного лица":"Aloqa uchun shaxsning FIOsi",
  "Номер телефона контактного лица":"Aloqa uchun shaxsning telefon raqami",
  "Данная информация не публикуется в вакансии. Используется только для уточнения автоматического подбора.":"Ushbu ma'lumot vakansiyada e'lon qilinmaydi. Faqat avtomatik tavsiyani takomillashtirish uchun foydalaniladi.",
  "Предпочтения к кандидатам":"Vakansiyaga mos keluvchilar",
  "Возраст":"Yoshi",
  "Вакансия подойдет для кандидатов":"Kimlarga mos keladi",
  "Доступы к вакансиям":"Qaysi vakansiyalarga ruxsati bor?",
  "Только свои вакансии":"Faqat o'zi yaratgan vakansiyalariga",
  "Все вакансии right":"Hamma vakansiyalarga",
  "Выбрать вакансию":"Vakansiya tanlash",
  "Роль":"Rol",
  "Администратор":"Administrator",
  "Подходит":"Tog'ri keladi",
  "Не подходит":"Tog'ri kelmaydi",
  "Заказчик одобрил":"Buyurtmachi ma'qulladi",
  "Заказчик не одобрил":"Buyurtmachi ma'qullamadi",
  "Может создавать вакансии, имеет доступ ко всей базе данных соискателей, управляет этапами отбора.":
    "Vakansiyalar yaratishi, barcha nomzodlarning bazasiga kirish huquqi va bosqichlarini boshqaradi.",
  "Имеет доступ к отобранным кандидатам, оценивает кандидатов на конкретной вакансии.":
    "Tanlangan nomzodlarni ko'rish huquqiga ega va muayyan vakansiya uchun nomzodlarni baholaydi.",
  "Добавить пользователя":"Foydalanuvchi qo'shish",
  "Прокомментируйте действие":"Izoh qoldiring",
  "Спам": "Spam",
  "Кандидатов пока нет":"Hozircha nomzodlar yo‘q",
  "Этап":"Bosqich",
  "Сортировка по опыту работы":"Ish tajribasi bo'yicha saralash",
  "Поля не может быть пустым":"Maydon bo'sh bo'lishi mumkin emas",
  "Описание вакансии не может быть менее 150 символов": "Vakansiya tavsifi 150 belgidan kam bo'lmasligi kerak",
  "Другие":"Boshqalar",
  "Дата публикации":"E'lon qilingan sana",
  "Уровень владения компьютером":"Kompyuter bilish darajasi",
  "Город(район) работы":"Ish shahri(tumani)",
  "Кандидат добавлен к вакансии vacancy_position_name":"Nomzod {vacancy_position_name} vakansiyaga qo'shildi",
  "Успешно добавлен":"Muvaffaqiyatli qo'shildi",
  "Перейти к вакансии":"Vakansiyaga o'tish",
  "Подтвердить адрес":"Manzilni tasdiqlash",
  "Собеседование":"Suhbat",
  "Собеседование не назначено":"Suhbat belgilanmagan",
  "Назначить собеседование":"Suhbatni belgilash",
  "Дата":"Sana",
  "Время":"Vaqt",
  "Добавить участников":"Ishtirokchilarni qo'shish",
  "Добавить ссылку на онлайн собеседование":"Onlayn suhbatga havolani qo'shing",
  "Укажите адрес, где будет проходить собеседование":"Suhbat o'tkaziladigan manzilni kiriting",
  "показать на карте":"xaritada ko‘rsatish",
  "Дата и время":"Sana va vaqt",
  "Отменить собеседование":"Suhbatni bekor qilish",
  "Собеседование проведено":"Suhbat o'tkazildi",
  "Календарь":"Kalendar",
  "Текст":"Matn",
  "Отладчик":"Отладчик",
  "Назначено собеседование":"Suhbat belgilandi",
  "Понедельник":"Dushanba", 
  "Вторник":"Seshanba", 
  "Среда":"Chorshanba", 
  "Четверг":"Payshanba", 
  "Пятница":"Juma", 
  "Суббота":"Shanba", 
  "Воскресенье":"Yakshanba",
  "Январь": "Yanvar", 
  "Февраль": "Fevral", 
  "Март": "Mart", 
  "Апрель": "Aprel", 
  "Май": "May", 
  "Июнь": "Iyun", 
  "Июль": "Iyul", 
  "Август": "Avgust", 
  "Сентябрь": "Sentabr", 
  "Октябрь": "Oktabr", 
  "Ноябрь": "Noyabr", 
  "Декабрь": "Dekabr",
  "Сегодня":"Bugun",
  "Пожалуйста, введите корректную ссылку":"Iltimos, toʻgʻri havola kiriting",
  "Добавить к вакансии":"Vakansiyaga biriktirish",

  "Вопрос":"Savol",
  "Варианты":"Variantlar",
  "Вариант":"Variant",
  "Добовить еще вариант": "Yana variant qo'shish",
  "Добавить вопрос": "Savol qo'shish",
  "Мои тесты":"Mening testlarim",
  "Название теста":"Test nomi",
  "Добавить тест":"Test qo'shish",
  "Вы уверены, что хотите удалить этот тест?":"Haqiqatan ham ushbu testni oʻchirmoqchimisiz?",
  "Тест успешно удалено!":"Test muvaffaqiyatli o'chirildi!",
  "Тесты":"Testlar",
  "Отправить тест":"Test yuborish",
  "прошёл":"topshirdi",
  "не прошёл":"kutilmoqda",
  "пройден":"topshirdi",
  "в ожидании":"kutilmoqda",
  "Создать тест":"Test yaratish",
  "Выберите тип":"Test turini tanlang",
  "Правильный вариант":"To'g'ri javob",
  "Неправильный вариант":"Noto'g'ri javob",
  "Редактировать тест":"Testni tahrirlash",
  "баллов":"ball",
  "Результат":"Natija",
  "Добавить резюме на вакансию position_name":"{position_name} vakansiyasiga rezyume qo'shish",
  "Добавить резюме":"Rezyume qo'shish",
  "Поиск резюме":"Rezyume qidirish",
  "на ISH.UZ":"ISH.UZ dan",
  "на HeadHunter.uz":"HeadHunter.uz dan",
  "на OLX.uz":"OLX.uz dan",
  "У вас нет тесты":"Sizda testlar yo'q",
  "Ссылка на онлайн собеседование":"Onlayn suhbatga havola",
  "указать":"tanlash",
  "Чтобы легко добавлять кандидатов с других порталов поиска работы на ISH.UZ, установите расширение для браузера Google Chrome.":"Kandidatlarni boshqa ish izlash portallaridan ISH.UZ ga qulay ravishda qo'shish uchun, Google Chrome brauzeri uchun kutubxonani o'rnating.",
  "После установки расширения на профиле кандидата появится кнопка «Добавить в ISH.UZ», если вы имеете доступ к контактным данным кандидата.":"Kutubxonani o'rnatganingizdan so'ng, kandidat profilida, kandidatning aloqa ma'lumotlarini ko'rishga huquqingiz bo'lsa \"ISH.UZ ga qo'shish\" tugmasi ko'rinadi.",
  "Установить расширение":"Kutubxonani o'rnatish",
  "Перейти на portal":"{portal} ga o'tish",
  "Эффективности рекрутеров":"Rekruterlarning samaradorligi",
  "Рекрутер / вакансия":"Rekruter / vakansiya",
  "Количество вакансии":"Vakansiyalar soni",
  "Статус вакансии":"Vakansiyalar statusi",
  "Закрытые вакансии":"Yopilgan vakansiyalar",
  "Все рекрутеры":"Barcha rekruterlar",
  "Закрыта":"Yopilgan",
  "Открыта":"Ochiq",
  "Дата открытия":"Ochilgan sana",
  "Дата закрытия":"Yopilgan sana",
  "Количество кандидатов":"Nomzodlar soni",
  "Длительность":"Davomiyligi",
  "Время закрытия вакансии":"Vakansiyalar yopish vaqti",
  "Публикация вакансии на сайтах поиска работы":"Vakansiyani ish izlash saytlarida e'lon qilish",
  "Подключить HeadHunter":"HeadHunter ga ulash",
  "HeadHunter подключен":"HeadHunter ulangan",
  "Подключить":"Ulash",
  "Если у вас есть аккаунт работодателя на сайте hh.uz, вы можете его подключить к ISH.UZ":"Agar sizda hh.uz saytida ish beruvchi sifatida akkauntingiz bo'lsa, uni ISH.UZ ga ulashingiz mumkin.",
  "Интеграции":"Integratsiyalar",
  "Отправлено тестовое задание":"Test topshirig‘i yuborildi",
  "Отправлено СМС сообщение":"SMS xabar yuborildi",
  "Доступы к этапам":"Qaysi bosqichlarga ruxsati bor?",
  "Все этапы":"Barcha bosqichlar",
  "Выбрать этапы":"Bosqichlarni tanlash",
  "Срок прохождения этапа":"Bosqichdan o'tish vaqti",
  "Количество дней":"Kunlar",
  "У вас не осталось вакансий для публикации в hh.uz":"Sizda hh.uz saytida joylashtirish uchun vakansiyalar qolmagan",
  "Анонимная премиум":"Anonim premium",
  "Премиум рекламная":"Premium reklamalik",
  "Анонимная: скрытый подбор":"Anonim: yashirin tanlov",
  "Стандарт плюс: автообновление":"Standart plyus: avto yangilash",
  "Премиум: неделя в топе":"Premium: bir xafta topda",
  "Рекламная":"Reklamalik",
  "Бесплатная":"Bepul",
  "Стандарт: без обновления":"Standart: yangilashsiz",
  "Прикрепить проверочный тест":"Sinov testini biriktirish",
  "Вы можете отправить тестовое задание для кандидатов, чтобы при отклике проверить их":"Nomzod taklif yuborishdan oldin ushbu testni yechish talab qilinadi",
  "Выберите тест":"Testni tanlang",
  "Результаты теста":"Test natijalari",
  "Cопроводительный текст":"Qo'shimcha matn",
  "У вас нет доступа на ISH.UZ PRO":"Sizda ISH.UZ PRO ga ruxsat yo'q",
  "Перейти на ISH.UZ":"ISH.UZ ga o'tish",
  "Просрочено": "Muddati o'tgan",
  "Просрочен": "Muddati o'tgan",
  "Превышено время нахождения кандидата на этом этапе":"Nomzodning ushbu bosqichda o'tkazgan vaqti oshib ketdi",
  "Доступ к системе для автоматизации подбора персонала ISH.UZ PRO":"Xodimlarni ishga olishni avtomatlashtiruvchi ISH.UZ PRO dan foydalanish",
  "за одного рекрутера":"har bir rekruter uchun",
  "Выберите подходящий тарифный план для подключения к системе по автоматизации подбора персонала ISH.UZ PRO":"Xodimlarni ishga olishni avtomatlashtiruvchi ISH.UZ PRO tizimiga ulanish uchun mos tarifni tanlang.",
  "Доступ к ISH.UZ PRO":"ISH.UZ PRO ga kirish imkoniyati",
  "Продлить доступы":"Ruxsatlarni uzaytirish",
  "Купить еще доступ":"Qushimcha ruxsat olish",
  "Отменить продление":"Uzaytirishni bekor qilish",
  "Создать счет":"To'lovni shakllantirish",
  "<b>Выберите</b> кому хотите продлить доступ.<br> После выбора внизу появится кнопка <br>\"<b>Создать счет</b>\"":"Foydalanuvchilarni <b>tanlang</b>.<br> Tanlaganingizdan keyin pastda \"<b>To'lovni shakllantirish</b>\" tugmasi chiqadi",
  "Рекрутера":"Rekruterlar",
  "Выбрано":"Tanlanganlar",
  "Выберите тариф":"Tarifni tanlang",
  "Стоимость каждого рекрутера recruiterPerPrice сум в месяц":"Har bir rekruterni oylik narxi {recruiterPerPrice} so'm",
  "Сумма тарифа":"Tarif narxi",
  "Сумма рекрутеров":"Rekruterlarni narxi",
  "Счёт успешно создано! Не забудьте его оплатить.":"Hisob yaratildi! Hisobni to'lashni unutmang.",
  "Количество доступов":"Ruxsatlar soni",
  "открытая":"ochiq",
  "закрытая":"yopiq",
  "Мои заявки":"Mening so'rovlarim",
  "Заявки":"So'rovlar",
  "Создать заявку":"So'rov yaratish",
  "Редактировать заявку":"So'rovni tahrirlash",
  "Открытые заявки":"Ochiq so'rovlar",
  "Закрытые заявки":"Yopilgan so'rovlar",
  "Вы уверены, что хотите отклонить эту заявку?":"Haqiqatan ham ushbu so'rovlarni rad qilmoqchimisiz?",
  "Отклонить":"Rad etish",
  "Отклоненные заявки":"Rad etilgan so'rovlar",
  "отклонена":"Rad etilgan",
  "Причина отклонений":"Rad etish sababi",
  "подтверждена":"tasdiqlangan",
  "Подтвердить":"Tasdiqlash",
  "Подтвержденные заявки":"Tasdiqlangan so'rovlar",
  "Добвить рекрутеров":"Rekruterlar qo'shish",
  "Количество рекрутеров":"Rekruterlar soni",
  "Для продление доступов выберите Администратора тоже":"Ruxsatlarni uzaytirish uchun Administratorni ham tanlang",
  "Сделать по умолчанию":"Keyingi vakansiyalarga qo'llash",
  "на linkedin.com":"linkedin.com dan",
  "Брендирование вакансий":"Vakansiyani brendlash",
  "Выпускники ВУЗа":"OTM bitiruvchilari",
  "Документооборот": "Hujjat almashinuvi",
  "Общий": "Umumiy",
  "Прямые заявители": "To'g'ridan-to'g'ri ariza beruvchilar",
  "Проактивный поиск": "Proaktiv qidiruv",
  "Outlook календарь подключен":"Outlook kalendar ulangan",
  "Синхронизировать ISH.UZ и Outlook календарь":"ISH.UZ va Outlook kalendarini sinxronlashtirish",
  "Если вы хотите синхронизировать ваши события с Outlook календарем, вы можете его подключить к ISH.UZ":"Tadbirlaringizni Outlook kalendaringiz bilan sinxronlashni istasangiz, uni ISH.UZ ga ulashingiz mumkin",
  "Воронка вакансии": "Vakansiya voronkasi",
  "Источник вакансии":"Vakansiya manbasi",
  "Заголовок": "Sarlavha",
  "Описание": "Tavsif",
};