<template>
  <div>
    <v-select
      v-model="vModel"
      :components="{Deselect, OpenIndicator}"
      @search="searched"
      @input="selected"
      :label="label"
      :reduce="item => item[valueColumn]?item[valueColumn]:item"
      :options="options"
    >
      <template v-slot:no-options="{ searching }">
        <template v-if="searching">{{"не найдено"}}</template>
        <template v-else>{{placeholder}}</template>
      </template>
    </v-select>
  </div>
</template>

<script>
import vueSelect from 'vue-select';
export default {
  components: {
    vueSelect
  },
  props: {
    placeholder: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: []
    },
    label_uz: {
      type: String
    },
    label_ru: {
      type: String
    },
    valueColumn: {
      type: String,
      required: false
    },
    oldterm: {
      type: String,
      required: false,
      default: ''
    },
  },
  data() {
    return {
        open: false,
        label: '',
        Deselect: null,
        vModel: this.value,
        OpenIndicator: {
            render: createElement => createElement('span', { class: { vs__toggle: true } })
        },
        oldSearch: "",
    };
  },
  created() {
    this.getLabel('ru');
  },
  methods: {
    selected(item) {
      this.$emit('input', this.vModel);
    },
    searched(query) {
        query = query.toLocaleLowerCase();
        if(query.length >= 3 && this.checkOldSearch(query, this.oldterm)){
            this.$emit('onSearched', query);
        }
    },
    getLabel(lang) {
      if (lang == 'ru') {
        this.label = this.label_ru ? this.label_ru : this.label_uz;
      }
      if (lang == 'uz') {
        this.label = this.label_uz ? this.label_uz : this.label_uz;
      }
    },
    checkOldSearch(newVal, oldVal){
      if(newVal && oldVal){
        if(newVal.length > oldVal.length){
            return newVal.indexOf(oldVal) != 0;
        }else{
          return oldVal.indexOf(newVal) != 0;
        }
      }
      return true;
    },
  }
};
</script>
<style>
.vs__clear {
  display: none;
}
.vs__dropdown-toggle {
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
    no-repeat right 0.75rem center/8px 10px;
  border: 1px solid #DCDFE6;
  border-radius: 4px;
  height: 40px;
}
.is-invalid .vs__dropdown-toggle {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat
      right 0.75rem center/8px 10px,
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e")
      #fff no-repeat center right 1.75rem / calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.v-select ul li {
  padding-left: 10px;
  padding-right: 10px;
}
.vs__selected-options {
  padding: 0 4px;
}
</style>
